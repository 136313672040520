import React, { Component } from 'react';
import { createPostRequest } from '../../auth/utils/axios-utils';

class AddNote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: "",
      content: "",
      contentEmpty: false,
    };
    this.submitModal = this.submitModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async submitModal() {
    if(this.state.content === "") {
      this.setState({...this.state, contentEmpty: true});
      return;
    }
    const note = {
      subject: this.state.subject,
      content: this.state.content,
      tag: "gen"
    };

    // Configure the add note request
    const path = "/addNote/" + this.props.pid;
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const body = { info: note }

    try {
      const addNoteResponse = await createPostRequest(path, headers, body);
      const keys = Object.keys(addNoteResponse);
      
      // TODO: This logic is really dumb and need to find a better way to do this
      keys.forEach(key => {
        this.props.addNote(addNoteResponse[key]);
      });

      // Reset the state
      this.setState({...this.state, 
        subject: "",
        content: "",
        contentEmpty: false,
      });

      
    } catch(error) {
      console.log(error);
      // Show the error toast
    }
  }

  closeModal() {
    // Reset the state
    this.setState({...this.state, 
      subject: "",
      content: "",
      contentEmpty: false,
    });

    this.props.handleOnExit();
  }

  render() {
    if(!this.props.visible) return null;

    return (
      <div className="fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
        <div className="bg-white w-1/5 overflow-auto overscroll-none pb-6 rounded">
          <div className="sticky top-0 z-40 flex items-center border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2] p-2 relative">
            <label className="text-2xl font-bold pb-2 flex-grow text-center">
              Add Note
            </label>
            <button 
              className="absolute right-4 px-3 py-1 text-xl font-semibold rounded-lg hover:bg-red-400"
              onClick={() => this.closeModal()}>
              X
            </button>
          </div>

          {/* Input Fields */}
          <div className="flex flex-col px-4 mt-2">
            <label className="font-medium text-gray-900">
              Subject
            </label>
            <input
              onChange={event => this.setState({...this.state, subject: event.target.value})}
              className="block w-full px-2 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
              placeholder="Subject"
            />

            <label className="font-medium text-gray-900 mt-4">
              Content   *
            </label>
            {this.state.contentEmpty ? 
              <textarea
                onChange={event => this.setState({...this.state, content: event.target.value, contentEmpty: false})}
                className="block w-full px-2 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded outline-none ring-4 ring-red-600 ring-opacity-50 resize-none"
                placeholder="Content"
                rows={9}
              />
            : 
              <textarea
                onChange={event => this.setState({...this.state, content: event.target.value})}
                className="block w-full px-2 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50 resize-none"
                placeholder="Content"
                rows={9}
              />
            }
            <label className="font-small italic  text-gray-900">
              * Indicates Required Field
            </label>
            <button 
              className="mt-4 inline-flex items-center justify-center px-8 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small text-white"
              onClick={() => this.submitModal()}>
                Submit
            </button>
          </div>
        </div>
      </div>
    )
  }
}
export default AddNote;