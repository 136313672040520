import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createGetRequest, createPostRequest } from '../../auth/utils/axios-utils';
import { GET_ORG_USER_PATH, ADD_ORG_USER_PATH, PERMISSIONS } from '../../../resources/Vault';

// Thunk for getting Org Users
export const fetchUsers = createAsyncThunk('users/fetchUsers', async (_, { rejectWithValue }) => {
  try {
    const headers = { "x-access-token": localStorage.getItem('auth_token') };
    return await createGetRequest(GET_ORG_USER_PATH, headers);
  } catch (error) {
    return rejectWithValue('Failed to fetch users');
  }
});

// Thunk for adding a new user
export const addUser = createAsyncThunk('users/addUser', async (user, { rejectWithValue }) => {
  try {
    const headers = { "x-access-token": localStorage.getItem('auth_token') };
    const body = {
      title: 'Add User',
      info: { email: user.newUserEmail, permissions: PERMISSIONS.USER },
    };
    const response = await createPostRequest(ADD_ORG_USER_PATH, headers, body);
    return response;
  } catch (error) {
    return rejectWithValue('Failed to add user');
  }
});

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    originalUserSet: [],
    loading: false,
    error: null,
    newUserEmail:"",
    showAddUserSuccessToast: false,
    showAddUserFailureToast: false,
    addUserOpacity: false
  },
  reducers: {
    setAddUserModalVisible(state, action) {
      state.showAddUserModal = action.payload;
      state.addUserOpacity = false;
      state.newUserEmail = "";
    },
    updateNewUserEmail(state, action) {
      state.newUserEmail = action.payload;
    },
    setAddUserSuccessToast(state, action) {
      state.showAddUserSuccessToast = action.payload;
    },
    setAddUserFailureToast(state, action) {
      state.showAddUserFailureToast = action.payload;
    },
    updateUserDisplayList(state, action) {
      state.users = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
        state.originalUserSet = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.users.push(action.payload);
        state.originalUserSet.push(action.payload);
        state.showAddUserModal = false;
        state.showAddUserSuccessToast = true;
        state.addUserOpacity = false;
      })
      .addCase(addUser.pending, (state) => {
        state.addUserOpacity = true;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.error = action.payload;
        state.showAddUserFailureToast = true;
        state.addUserOpacity = false;
      });
  },
});

export const { setAddUserModalVisible, updateNewUserEmail, setAddUserSuccessToast, setAddUserFailureToast, updateUserDisplayList } = usersSlice.actions;
export default usersSlice.reducer;

