/**
   * Returns all of the active sessions 
   * @param {Array<Object>} sessions Array of sessions for a patient 
   * @returns An array of active sessions Objects
   */
export function getActiveSessions(sessions) {
    let activeSessions = []
    sessions.forEach(session => {
        if (session.active) {
            activeSessions.push(session);
        }
    })
    return activeSessions;
}

/**
   * Returns all of the inactive sessions
   * Sorts them into two arrays, pastSessions and upcomingSessions
   * @param {Array<Object>} sessions Array of sessions for a patient 
   * @returns An Object of inactive sessions sorted by past/upcoming
   */
export function getInactiveSessions(sessions) {
    const result = {
        upcomingSessions: [],
        pastSessions: []
    };

    const currentDate = new Date();

    sessions.forEach(session => {
        if (!session.active) {
            const startDate = new Date(session.start_date);

            // Sort the sessions into past/upcoming session arrays
            if (startDate < currentDate) {
                result.pastSessions.push(session);
            } else {
                result.upcomingSessions.push(session);
            }
        }
    });
    return result;
}

/**
   * Removes a session based on its ID value
   * @param {Array<Object>} sessions Array of sessions for a patient 
   * @param {String} sessionId The id of the session to remove
   * @returns The updated sessions array
   */
export function removeSession(sessions, sessionId) {
    const updatedSessions = sessions.filter(session => session.id !== sessionId);
    return updatedSessions;
}