import React, { Component } from 'react';
import { BiDotsVerticalRounded } from "react-icons/bi";
import { withRouter } from 'react-router';

// Custom components and utils
import { PopOverMenu } from '../../core/components/PopOverMenu';
import ViewNote from '../modals/ViewNote';

class NoteSimple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popOverMenuItems: [
        {title: "View Note", onClick: this.showViewNoteModal}
      ],
      showPopOverMenu: false,
      showViewNoteModal: false
    };
    this.showViewNoteModal = this.showViewNoteModal.bind(this);
    this.exitViewNoteModal = this.exitViewNoteModal.bind(this);
    this.handleClosePopOverMenu = this.handleClosePopOverMenu.bind(this);
    // Create the button ref for pop-over menu
    this.buttonRef = React.createRef();
  }

  showViewNoteModal = () => {
    this.setState({...this.state,showViewNoteModal: true, showPopOverMenu: false});
  }
  exitViewNoteModal = () => {
    this.setState({...this.state,showViewNoteModal: false});
  }
  handleClosePopOverMenu () {
    this.setState({...this.state,showPopOverMenu: false});
  }
  togglePopOverMenu = () => {
    this.setState((prevState) => ({
      showPopOverMenu: !prevState.showPopOverMenu
    }));
  };

  render() {
    return (
      <div className="w-full flex flex-row py-4 hover:bg-[#FFFFF0]">
        <div className="w-3/5 flex flex-row ml-4">
          <div className="w-1/4">
            <label className="text-lg">{this.props.note.date}</label>
          </div>
          <div className="w-1/4">
            <label className="text-lg">{this.props.note.author}</label>
          </div>
          <div className="w-1/4">
            {this.props.note.subject ? 
              <div className="text-lg w-full truncate pr-4">{this.props.note.subject}</div> :
              <label className="text-lg">N/A</label>
            }
          </div>
          <div className="w-1/4">
            <label className="text-lg">{this.props.note.tag}</label>
          </div>
        </div>
        <div className="w-2/5 flex flex-row justify-between">
          <label className="text-lg pr-6 truncate">{this.props.note.content}</label>
          <div ref={this.buttonRef}>
            <BiDotsVerticalRounded
              className="mt-2 mr-2 cursor-pointer"
              onClick={this.togglePopOverMenu}
            />
            <PopOverMenu
              showMenu={this.state.showPopOverMenu}
              handleOnClose={this.handleClosePopOverMenu}
              menuItems={this.state.popOverMenuItems}
              buttonRef={this.buttonRef}
            />
          </div>
        </div>
        <ViewNote
          visible={this.state.showViewNoteModal}
          handleOnExit={this.exitViewNoteModal}
          content={this.props.note.content}
          subject={this.props.note.subject}/>
      </div>
    )
  }
}

export default withRouter(NoteSimple);