import React, { Component } from 'react';
import { withRouter } from 'react-router';
import TabBar from '../components/TabBar';
import { BiDotsVerticalRounded } from "react-icons/bi";

import { PopOverMenu } from '../../core/components/PopOverMenu';
import { getMonitoringSessions } from '../../../resources/url_construction';
import { SessionCreation } from '../modals/SessionCreation';
import { ComplianceBucket } from "../components/ComplianceBucket";
import { LineChart } from '../components/charts/LineChart';
import ComplianceTriageObject from '../../portal/components/ComplianceTriageObject';
import { ClickToConfirm } from '../modals/ClickToConfirm';

import { createGetRequest } from '../../auth/utils/axios-utils';
import { get_progress } from '../../core/utils/session-collection-progress';
import { FORM_OVER_TIME, FORM_GRAPH, GAIT_VELOCITY_GRAPH, GAIT_VELOCITY_GRAPH_NAME } from '../../../resources/Config';
import { getActiveSessions, getInactiveSessions, removeSession } from '../utils/session-management-helpers';
import { cardio } from 'ldrs';
import { PiNuclearPlantFill } from 'react-icons/pi';


class SessionManagement extends Component {
  constructor(props) {
    super(props);
    // Access query params from the location object
    const queryParams = new URLSearchParams(this.props.location.search);
    this.state= {
      sessionId: queryParams.get('sid'),
      pid: this.props.match.params.pid,
      activeSessions:[],
      inactiveSessions:[],
      sessions: [],
      graph: "Form",
      showAddSessionModal: false,
      showPopOverMenu: false,
      showDeleteSessionModal: false,
      popOverMenuItems: [
        {title: "Add Session", onClick: this.showAddSessionModal},
      ],
      displaySession: {},
      graphData: [],
      loadingSessions: true
    }
    cardio.register();
    // Binding Functions
    this.showAddSessionModal = this.showAddSessionModal.bind(this);
    this.closeAddSessionModal = this.closeAddSessionModal.bind(this);
    this.handleClosePopOverMenu = this.handleClosePopOverMenu.bind(this);
    this.handleCloseDeleteSessionModal = this.handleCloseDeleteSessionModal.bind(this);
    this.removeSession = this.removeSession.bind(this);
    this.addNewSession = this.addNewSession.bind(this);
    this.setGraph = this.setGraph.bind(this);
    this.togglePopOverMenu = this.togglePopOverMenu.bind(this);

    // Create the buttonRef for the popOverMenu
    this.buttonRef = React.createRef();
  };

  async componentDidMount() {
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const path = getMonitoringSessions(this.state.pid);
    try {
      // Get all of the monitoring sessions and sort them into inactive/active 
      const getMonitoringSessionsResponse = await createGetRequest(path, headers);

      // Grab the display session from URL if its there
      const selectedSession = getMonitoringSessionsResponse.find(
        (session) => session.id === this.state.sessionId
      );

      // Handle empty API response
      if (getMonitoringSessionsResponse.length === 0) {
        this.setState({
          ...this.state,
          activeSessions: [],
          inactiveSessions: [],
          sessions: [],
          displaySession: null,
          loadingSessions: false
        });
        return;
      }

      // Get the session we want to display as a default and pull its data
      const displaySession = selectedSession || getMonitoringSessionsResponse[0];
      const graphData = await this.updateActiveSession(displaySession, false);
      this.setState({
        ...this.state,
        activeSessions: getActiveSessions(getMonitoringSessionsResponse),
        inactiveSessions: getInactiveSessions(getMonitoringSessionsResponse),
        sessions: getMonitoringSessionsResponse,
        displaySession: displaySession,
        graphData: graphData,
        loadingSessions: false
      });
    } catch(error) {
      console.log(error);
    }
  }

  /**
   * Updates the active session in the sesion view section
   * @param {Object} session The session to be displayed
   * @param {Boolean} shouldUpdateState Used to determine if state should be updated
   */
  async updateActiveSession(session, shouldUpdateState) {
    const path = "getSessionDatasets/" + this.state.pid + "/" + session.id;
    const headers = { "x-access-token": localStorage.getItem("auth_token") };

    try {
      const graphData = await createGetRequest(path, headers);
      if (shouldUpdateState) {
        this.setState({
          displaySession: session,
          graphData: graphData,
        });
        return;
      }
      return graphData;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /**
   * Shows the session creation window
   */
  showAddSessionModal = () => {
    this.setState({...this.state,showAddSessionModal: true, showPopOverMenu: false});
  };

  /**
   * Closes the session creation window
   */
  closeAddSessionModal() {
    this.setState({...this.state,
      showAddSessionModal: false
    });
  };
  /**
   * Adds a new session object to the session list
   * @param {Object} session 
   */
  addNewSession(session) {
    this.setState({...this.state,
      showAddSessionModal: false,
      sessions: [...this.state.sessions, session]
    });
  };
  setGraph(graph) {
    this.setState({...this.state,graph: graph});
  };

  togglePopOverMenu = () => {
    this.setState((prevState) => ({
      showPopOverMenu: !prevState.showPopOverMenu
    }));
  };
  handleClosePopOverMenu () {
    this.setState({...this.state,showPopOverMenu: false});
  }

  /**
   * Shows the session creation window
   */
  showDeleteSessionModal = () => {
    this.setState({...this.state,showDeleteSessionModal: true});
  };

  handleCloseDeleteSessionModal() {
    this.setState({...this.state,showDeleteSessionModal: false});
  }
  async removeSession(sessionId) {
    // Remove the session based on the sessionId
    let prevSessions = this.state.sessions;
    let updatedSessions = removeSession(prevSessions, sessionId);
    // Update the display session
    let updatedDisplaySession = {};
    let updatedGraphData;
    if(updatedSessions.length !== 0) {
      updatedDisplaySession = updatedSessions[0];
      updatedGraphData = await this.updateActiveSession(updatedDisplaySession, false);
    }
    // Get the inactive and active sessions
    let updatedActiveSessions = getActiveSessions(updatedSessions);
    let updatedInactiveSessions = getInactiveSessions(updatedSessions);

    this.setState({...this.state,
      sessions: updatedSessions,
      displaySession: updatedDisplaySession,
      activeSessions: updatedActiveSessions,
      inactiveSessions: updatedInactiveSessions,
      graphData: updatedGraphData,
      showDeleteSessionModal: false
    });
  }

  render() {
    // True -> Sessions have loaded and we have inactive. False -> Sessions are loading or no inactive sessions
    const inactiveSessions = !this.state.loadingSessions && (
      this.state.inactiveSessions?.pastSessions?.length > 0 ||
      this.state.inactiveSessions?.upcomingSessions?.length > 0
    );
    // Boolean true -> Sessions have loaded and we have past sessions. false -> Sessions are loading or no past sessions
    const pastSessions = !this.state.loadingSessions && this.state.inactiveSessions?.pastSessions?.length > 0;
    // Boolean true -> Sessions have loaded and we have upcoming sessions. false -> Sessions are loading or no upcoming sessions
    const upcomingSessions = !this.state.loadingSessions && this.state.inactiveSessions?.upcomingSessions?.length > 0;
    return (
      <section>
        <TabBar activeTab={"session"}/>

        {/* Main Section for the session overview page */}
        <section className="flex flex-row h-screen w-full p-4">
          
          {/* Section for the view sessions */}
          <section className="flex flex-col w-1/5 mr-2 mb-16 border-4 border-black overflow-auto">
            <div className="flex flex-row w-full items-center justify-between border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2]">
              <label className="text-2xl font-bold pb-2 flex-grow text-center -mr-6">
                Active Sessions
              </label>
              <div ref={this.buttonRef}>
                <BiDotsVerticalRounded 
                  className="w-6 cursor-pointer text-xl font-bold"
                  onClick={this.togglePopOverMenu}
                />
              </div>
            </div>
            <div>
              {this.state.loadingSessions &&
                <div className="w-full flex justify-center mt-10">
                  <l-cardio size="80" stroke="6" speed="2" color="black" />
                </div> 
              }
              {!this.state.loadingSessions && this.state.activeSessions.length == 0 ?
                <div className="w-full flex-col items-center mt-4">
                  <div className="text-center">
                    <label className="text-2xl">
                      No Active Sessions
                    </label>
                  </div>
                </div> 
              : 
                <div className="px-2">
                  {this.state.activeSessions && this.state.activeSessions.map(block => 
                    <div onClick={() => this.updateActiveSession(block, true)}>
                      <ComplianceTriageObject
                        key={block.rules.title}
                        pid={this.state.pid}
                        id={block.sid}
                        {...block} />
                    </div>
                  )}
                </div>
              }
            </div>
            <PopOverMenu
              showMenu={this.state.showPopOverMenu}
              handleOnClose={this.handleClosePopOverMenu}
              menuItems={this.state.popOverMenuItems}
              buttonRef={this.buttonRef}
            />
            {/* Container for inactive sessions */}
            <div className="flex flex-row w-full mt-4 items-center justify-between border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2]">
              <label className="text-2xl font-bold pb-2 flex-grow text-center -mr-6">
                Inactive Sessions
              </label>
            </div>
            {/* Loading animation for the inactive sessions */}
            {this.state.loadingSessions && 
              <div className="w-full flex justify-center mt-10">
                <l-cardio size="80" stroke="6" speed="2" color="black" />
              </div>
            }

            {/* Check if we have inactive sessions to display */}
            {inactiveSessions && upcomingSessions ?
              <>
                <label className="text-xl font-bold flex-grow text-center">
                  Upcoming Sessions
                </label>
                <div className="px-2 pb-2 border-b-2">
                  {this.state.inactiveSessions.upcomingSessions.map((block) => (
                    <div
                      key={block.rules.title}
                      onClick={() => this.updateActiveSession(block, true)}
                    >
                      <ComplianceTriageObject
                        pid={this.state.pid}
                        id={block.id}
                        {...block}
                      />
                    </div>
                  ))}
                </div>
              </> : null
            }
            {/* Check if we have inactive sessions to display */}
            {inactiveSessions && pastSessions ?
              <>
                <label className="text-xl mt-2 font-bold flex-grow text-center">
                  Past Sessions
                </label>
                <div className="px-2">
                  {this.state.inactiveSessions.pastSessions.map((block) => (
                    <div
                      key={block.rules.title}
                      onClick={() => this.updateActiveSession(block, true)}
                    >
                      <ComplianceTriageObject
                        pid={this.state.pid}
                        id={block.id}
                        {...block}
                      />
                    </div>
                  ))}
                </div>
              </> : null
            }
            {/* Check if we have any inactive sessions */}
            {!inactiveSessions && !this.state.loadingSessions ?
              <div className="w-full flex-col items-center mt-4">
                <div className="text-center">
                  <label className="text-2xl">
                    No Inactive Sessions
                  </label>
                </div>
              </div> : null
            }
          </section>

          {/* Section for the session view */}
          <section className="flex flex-col w-4/5 ml-2 mb-16 border-4 border-black">
            {this.state.displaySession && Object.keys(this.state.displaySession).length > 0 ? (
              <>
                {/* Session Information */}
                <div className="flex flex-row items-center bg-[#E5E4E2] border-b-4 border-[#D3D3D3] shadow-lg rounded">
                  <div className="flex-grow text-center my-4">
                    <label className="text-3xl font-bold">
                      {this.state.displaySession.rules
                        ? this.state.displaySession.rules.title
                        : 'Loading...'}
                    </label>
                  </div>
                  <div className="mr-4">
                    <button
                      onClick={this.showDeleteSessionModal}
                      className="px-8 py-2 rounded-md bg-primary hover:bg-accent text-white"
                    >
                      Delete
                    </button>
                  </div>
                </div>

                <div className="h-full">
                  <div className="flex h-5/6">
                    <ComplianceBucket
                      metrics={this.state.displaySession.metrics}
                      rules={this.state.displaySession.rules}
                      startDate={this.state.displaySession.start_date}
                      evaluation={this.state.displaySession.evaluation}
                      sets={this.state.displaySession.sets}
                      houseKeeping={this.state.displaySession.housekeeping}
                      graph={this.state.graph}
                      setGraph={this.setGraph}
                      collectionProgress={get_progress(
                        this.state.displaySession.sets.length,
                        this.state.displaySession.rules.min_threshold,
                        this.state.displaySession.housekeeping
                      )}
                    />
                    <div className="h-full w-3/4 rounded-xl px-3 mt-3 ml-6 overflow-auto">
                      {this.state.graph === "Form" &&
                      this.state.graphData && this.state.graphData.length > 0 ? (
                        <LineChart 
                          data={this.state.graphData} 
                          labels={FORM_OVER_TIME} 
                          name={FORM_GRAPH}
                          legendSide="Top"
                          showGranularData={this.granularDataWorkaround}
                        />
                      ) : null}
                      
                      {this.state.graph === "Gait Velocity" ? (
                        <LineChart
                          data={this.state.graphData}
                          labels={GAIT_VELOCITY_GRAPH}
                          name={GAIT_VELOCITY_GRAPH_NAME}
                          legendSide="Top"
                          showGranularData={null}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center py-8">
                {/* No Session Message */}
                <p className="text-2xl font-bold">There are no sessions for this patient</p>
                <p className="text-lg mt-2">Add a new session for this user</p>
                <button 
                  className="px-12 py-4 mt-2 rounded-md bg-primary hover:bg-accent text-white"
                  onClick={this.showAddSessionModal}
                >
                  Add Session
                </button>
              </div>
            )}
          </section>

        </section>
        <SessionCreation
          visible={this.state.showAddSessionModal}
          exit={this.closeAddSessionModal}
          submit={this.addNewSession}/>
        <ClickToConfirm 
          visible={this.state.showDeleteSessionModal}
          close={this.handleCloseDeleteSessionModal}
          submit={this.removeSession}
          data={this.state.displaySession}/>
      </section>
    )
  }
};

export default withRouter(SessionManagement);
