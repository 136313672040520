import React, { Component } from 'react';
import { withRouter } from 'react-router';

/**
 * Tab bar that displays the tabs
 */
class TabBar extends Component {
  constructor(props) {
    /**
     * TabBar component state
     * @type {Object}
     * @property {Array<tab>} tabs Array of tabs
     */
    super(props);
    this.state = {
      activeTab: this.props.activeTab,
      pid: ''
    }
    this.routeToPatientOverview = this.routeToPatientOverview.bind(this);
    this.routeToSessionOverview = this.routeToSessionOverview.bind(this);
    this.routeToMSKData = this.routeToMSKData.bind(this);
    this.routeToRespiratoryData = this.routeToRespiratoryData.bind(this);
  }
  
  componentDidMount() {
    const parts = window.location.pathname.split('/');
    if(parts[3]){
      this.setState({...this.state,pid: parts[3]});
    }
  }

  routeToPatientOverview() {
    const path = '/portal/patient-data/' + this.state.pid.toString();
    this.props.history.push({pathname: path});
  }
  routeToSessionOverview() {
    const path = '/portal/patient-data/' + this.state.pid.toString() + '/sessions';
    this.props.history.push({pathname: path});
  }
  routeToMSKData() {
    const path = '/portal/patient-data/' + this.state.pid.toString() + '/msk';
    this.props.history.push({pathname: path});
  }
  routeToRespiratoryData() {
    const path = '/portal/patient-data/' + this.state.pid.toString() + '/resp';
    this.props.history.push({pathname: path});
  }

  render() {
    return (
      <div className="w-full flex flex-row border border-black h-16">
        {this.state.activeTab === "patient" ?
          <button className="w-1/4 bg-primary">
            <label className="cursor-pointer text-xl text-white">
              Patient Overview
            </label>
          </button>
        :
          <button 
            className="w-1/4 hover:bg-accent border border-black hover:font-bold"
            onClick={this.routeToPatientOverview}>
            <label className="cursor-pointer text-xl">
              Patient Overview
            </label>
          </button>
        }
        

        {this.state.activeTab === "session" ?
          <button className="w-1/4 bg-primary">
            <label className="cursor-pointer text-xl text-white">
              Session Overview
            </label>
          </button>
        :
          <button
            className="w-1/4 hover:bg-accent border border-black hover:font-bold"
            onClick={this.routeToSessionOverview}>
            <label className="cursor-pointer text-xl">
            Session Overview
            </label>
          </button>
        }

        {this.state.activeTab === "msk" ?
          <button className="w-1/4 bg-primary">
            <label className="cursor-pointer text-xl text-white">
              Musculoskeletal Data
            </label>
          </button>
        :
          <button
            className="w-1/4 hover:bg-accent border border-black hover:font-bold"
            onClick={this.routeToMSKData}>
            <label className="cursor-pointer text-xl">
              Musculoskeletal Data
            </label>
          </button>
        }

        {this.state.activeTab === "resp" ?
          <button className="w-1/4 bg-primary">
            <label className="cursor-pointer text-xl text-white">
              Respiratory Data
            </label>
          </button>
        :
          <button
            className="w-1/4 hover:bg-accent border border-black hover:font-bold"
            onClick={this.routeToRespiratoryData}>
            <label className="cursor-pointer text-xl">
              Respiratory Data
            </label>
          </button>
        }
      </div>
    )
  }   
}

export default withRouter(TabBar);