import React, { Component } from 'react';
import { withRouter } from 'react-router';

// Custom Components
import TabBar from '../components/TabBar';
import { Dropdown } from '../../core/components/Dropdown';
import DateSelection from '../../core/components/DateSelection';
import Graph from '../components/charts/Graph';

//Custom Utils and Functions
import { createGetRequest } from '../../auth/utils/axios-utils';
import { sortDates, updateRespAggData, updatePatientRespiratoryData, formatDatesForDropdown, addPeakFlowData } from '../utils/respiratory-data-helpers';
import { SHAKE_COUNT } from '../../../resources/Config';


// Respiratory graph set for dropdown
const respGraphs = [
  {
    value: "peakFlow",
    label: "Peak Flow"
  }
];


class RespiratoryData extends Component {
  constructor(props) {
    super(props);
    // Access query params from the location object
    const queryParams = new URLSearchParams(this.props.location.search);
    this.state = {
      period: queryParams.get('period'),
      pid: this.props.match.params.pid,
      data: [],
      dates: [],
      activeDate: '',
      aggData: '',
      peakFlowInput: '',
      peakFlowError: false,
      graph: 'peakFlow',
      graphTitle: 'Peak Flow'
    }
    this.setDate = this.setDate.bind(this);
    this.changeGraph = this.changeGraph.bind(this);
    this.renderList = this.renderList.bind(this);
    this.addPeakFlowData = this.addPeakFlowData.bind(this);
  };

  async componentDidMount() {
    // Configure the API request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const path = '/getRespAggDates/' + this.state.pid;
    try {
      const getAggDatesResponse = await createGetRequest(path, headers);
      let dates = sortDates(getAggDatesResponse);
      let aggData = await updateRespAggData(dates[0], this.state.pid);
      let data = await updatePatientRespiratoryData(this.state.pid, dates[0]);
      this.setState({...this.state, 
        dates: dates,
        activeDate: dates[0],
        aggData: aggData,
        data: data
      });
    } catch(error) {
      console.log(error);
    }
  }

  /**
   * Sets the active date and updates data accordingly
   * @param {string} date Date 
   */
  async setDate(date) {
    let newAggData = await updateRespAggData(date, this.state.pid);
    let newPatientData = await updatePatientRespiratoryData(this.state.pid, date);

    this.setState({...this.state,
      aggData: newAggData,
      data: newPatientData,
      activeDate: date
    });
  }

  /**
   * Changes the graph to display
   * @param {string} graph Graph to display
   */
  changeGraph(graph) {
    if(graph === 'peakFlow') {
      this.setState({...this.state, graph: graph, graphTitle: 'Peak Flow'});
    }
  }

  renderList() {
    const renderedItems = [];
    for (const key in this.state.aggData.balance_dates) {
      const value = this.state.aggData.balance_dates[key];
      renderedItems.push(
        <li key={key} className="flex flex-row justify-center items-center w-full py-2 px-4 border-b-2 border-gray-200">
          <div className="w-1/2 text-left">{key}</div>
          <div className="w-1/2 text-right">{value}</div>
        </li>
      );
    }
    return renderedItems;
  }

  async addPeakFlowData() {
    // Validate the peak flow measurement
    const peakFlow = parseFloat(this.state.peakFlowInput);
    if (isNaN(peakFlow) || peakFlow < 300 || peakFlow > 700) {
      this.setState({...this.state, peakFlowError: true, peakFlowInput: ''});
      return;
    }
    // Call the backend endpoint to add the peakflow data
    addPeakFlowData(this.state.peakFlowInput, this.state.pid);
    let updatedData = this.state.data.push(addPeakFlowData);
    this.setState({...this.state, peakFlowError: false, peakFlowInput: '', data: updatedData});
  }

  render() {
    return (
      <section>
        <TabBar activeTab={"resp"}/>

        {/* Main Section for the respiratory data page */}
        <section className="flex flex-row h-screen w-full p-4">
          
          {/* Section for the aggregate data */}
          <section className="flex flex-col w-1/5 mr-2 mb-16 border-4 border-black overflow-auto">
            {/* Title Section */}
            <div className="flex flex-row w-full items-center justify-between border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2]">
              <label className="text-2xl font-bold pb-2 flex-grow text-center">
                View Aggregate Data
              </label>
            </div>

            {/* Date Selector */}
            <div className="flex flex-row mt-2 pl-4">
              <h1 className="text-primary text-center mt-2 text-xl font-bold mb-2"> Select Month:</h1>
              <div className="w-1/2 ml-4">
                <DateSelection dates={formatDatesForDropdown(this.state.dates)} setDate={this.setDate} />
              </div>
            </div>

            {/* Graph Selector */}
            <div className="flex flex-row mt-2 pl-4">
              <h1 className="text-primary text-center mt-2 text-xl font-bold mb-2"> Select Graph:</h1>
              <div className="w-1/2 ml-4">
                <Dropdown options={respGraphs} setValue={this.changeGraph}/>
              </div>
            </div>
            
            {/* Peak Flow Collection Section */}
            <div className="flex flex-col border-t-2 border-black mt-4 w-full items-center">
              <div className="flex flex-row w-full items-center justify-between border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2]">
                <label className="text-2xl font-bold pb-2 flex-grow text-center">
                  Collect Peak Flow Data
                </label>
              </div>
              <label className="text-xl mt-4 font-bold pb-2 flex-grow text-center">
                Enter Peak Flow Reading
              </label>
              <div className="mx-4">
                {this.state.peakFlowError ? 
                  <div>
                    <input
                      onChange={event => this.setState({...this.state,peakFlowInput:event.target.value})}
                      onKeyPress={this.handleKeypress}
                      className="block w-full px-2 py-2 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                      placeholder="Peak Flow"
                    />
                    <label className="font-small italic text-red-600 text-gray-900">
                      * Peak Flow Value is Invalid
                    </label>
                  </div>
                :
                  <input
                    onChange={event => this.setState({...this.state,peakFlowInput:event.target.value})}
                    onKeyPress={this.handleKeypress}
                    className="block w-full px-2 py-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                    placeholder="Peak Flow"
                  />
                }
                <button 
                  className= "mt-2 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
                  onClick={this.addPeakFlowData}>
                    Submit
                </button>
              </div>
            </div>

            {/* Total Observations Section */}
            <div className="flex flex-col border-t-2 border-black mt-4 w-full items-center">
              {/* Title Section */}
              <div className="flex flex-row w-full items-center justify-between border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2] py-2">
                <label className="text-2xl font-bold text-center w-full">
                  {this.state.aggData.count} Sets Collected For {this.state.activeDate}
                </label>
              </div>

              {/* Information Section */}
              <div className="flex flex-row w-full justify-center bg-gray-100 py-2 px-4 border-b-2 border-gray-300">
                <div className="w-1/2 text-left text-lg font-semibold">Day</div>
                <div className="w-1/2 text-right text-lg font-semibold">Sets Collected</div>
              </div>

              {/* Container for the sets collected data */}
              <ul className="w-full font-bold space-y-2 text-base text-center">
                {this.renderList()}
              </ul>
            </div>

          </section>

          {/* Section for the graphs */}
          <section className="flex flex-col w-4/5 ml-2 mb-16 border-4 border-black">
            {/* Graphs */}
            <div className="flex flex-row w-full py-4 items-center justify-between border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2]">
              <label className="text-2xl font-bold pb-2 flex-grow text-center">
                {this.state.graphTitle}
              </label>
            </div>
            {!this.state.data && 
              <div className="flex flex-col h-screen w-full pl-4 mt-10">
                <label className="text-6xl font-extrabold text-primary text-center"> 
                  No Respiratory Data Collected
                </label>
                <div className="text-2xl text-center text-gray-500 mt-6">
                  Collect peak flow data to view charts of the collected data
                </div>
              </div>
            }
            <Graph 
              graphSet={respGraphs}
              data={this.state.data}
              graph={"peakFlow"}/>
          </section>
        </section>
      </section>
    )
  }
};

export default withRouter(RespiratoryData);