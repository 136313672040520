/*
 * 
 * THIS FILE WILL CONTAIN CONFIGURATION VALUES FOR CERTAIN COMPONENETS THROUGHOUT OUR PROJECT
 * ANY TIME YOU ARE CREATING COMPONENTS THAT USE CONFIGURATION DATA, ADD THAT HERE
 *
 */

// TAB COMPONENT CONFIGURATION
export const TABS = [
  {
    tabName: 'Overview',
    tabID: '0',
    tabIsActive: true
  },
  {
    tabName: 'Musculoskeletal Data',
    tabID: '1',
    tabIsActive: false
  },
  {
    tabName: 'Respiratory Data',
    tabID: '2',
    tabIsActive: false
  },
]

// GRAPH COMPONENT CONFIG DATA
export const FORM_OVER_TIME = {
  x : "Date",
  y : "Form",
  title : "Form Over Time",
  dataKeyX : "date",
  dataKeyY : "form",
};
export const FORM_GRAPH = "FORM";

export const GAIT_VELOCITY_GRAPH = {
  x : "Date",
  y : "Gait Velocity",
  title : "Gait Velocity",
  dataKeyX : "date",
  dataKeyY : "gait_velocity",
};
export const GAIT_VELOCITY_GRAPH_NAME = "GAIT VELOCITY";

export const CLASSIFICATION_CONFIDENCE = {
  x : "Date",
  y : "Confidence",
  title : "Confidence Over Time",
  dataKeyX : "date",
  dataKeyY : "confidence",
}
export const CLASSIFICATION_GRAPH = "CLASSIFICATION";
export const PEAK_FLOW = {
  x : "Date",
  y : "lpm",
  title : "Peak Flow",
  dataKeyX : "date",
  dataKeyY : "lpm",
}
export const PEAK_FLOW_GRAPH = "PEAK FLOW";
export const TRIP_COUNT = {
  x : "Date",
  y : "Trip Count",
  title : "Trip Count By Date",
  dataKeyX : "date",
  dataKeyY : "trip_count",
}

export const SHAKE_COUNT = {
  x : "Inhaler Shake Count",
  y : "Number of Occurances",
  title : "Inhaler Shake Count Distribution",
  dataKeyY : "y"
}

export const MONTH_LABELS = [
  {
    value: "01",
    label: "Jan"
  },
  {
    value: "02",
    label: "Feb"
  },
  {
    value: "03",
    label: "Mar"
  },
  {
    value: "04",
    label: "Apr"
  },
  {
    value: "05",
    label: "May"
  },
  {
    value: "06",
    label: "Jun"
  },
  {
    value: "07",
    label: "Jul"
  },
  {
    value: "08",
    label: "Aug"
  },
  {
    value: "09",
    label: "Sept"
  },
  {
    value: "10",
    label: "Oct"
  },
  {
    value: "11",
    label: "Nov"
  },
  {
    value: "12",
    label: "Dec"
  }
]

export const DATE_LABELS = [
  {
    value: "01",
    label: "1",
  },
  {
    value: "02",
    label: "2"
  },
  {
    value: "03",
    label: "3",
  },
  {
    value: "04",
    label: "4"
  },
  {
    value: "05",
    label: "5",
  },
  {
    value: "06",
    label: "6"
  },
  {
    value: "07",
    label: "7",
  },
  {
    value: "08",
    label: "8"
  },
  {
    value: "09",
    label: "9",
  },
  {
    value: "10",
    label: "10"
  },
  {
    value: "11",
    label: "11",
  },
  {
    value: "12",
    label: "12"
  },
  {
    value: "13",
    label: "13",
  },
  {
    value: "14",
    label: "14"
  },
  {
    value: "15",
    label: "15",
  },
  {
    value: "16",
    label: "16"
  },
  {
    value: "17",
    label: "17"
  },
  {
    value: "18",
    label: "18",
  },
  {
    value: "19",
    label: "19"
  },
  {
    value: "20",
    label: "20",
  },
  {
    value: "21",
    label: "21"
  },
  {
    value: "22",
    label: "22",
  },
  {
    value: "23",
    label: "23"
  },
  {
    value: "24",
    label: "24",
  },
  {
    value: "25",
    label: "25"
  },
  {
    value: "26",
    label: "26",
  },
  {
    value: "27",
    label: "27"
  },
  {
    value: "28",
    label: "28",
  },
  {
    value: "29",
    label: "29"
  },
  {
    value: "30",
    label: "30",
  },
  {
    value: "31",
    label: "31"
  },
]

// CREATE YEAR OBJECT THAT HOLDS YEARS FROM NOW - 120 YEARS AGO
let years = []
let now = new Date().getFullYear();
let end = now - 120;
for (let i = now; i >= end; --i) {
  let value = i.toString();
  let label = i.toString();

  let yearObject = {
    value: value,
    label: label
  }

  years.push(yearObject);
}

let futureYears = []
let futureNow = new Date().getFullYear();
let futureEnd = futureNow + 10;
for (let i = futureNow; i <= futureEnd; ++i) {
  let futureValue = i.toString();
  let futureLabel = i.toString();

  let futureYearObject = {
    value: futureValue,
    label: futureLabel
  }

  futureYears.push(futureYearObject);
}

export const YEAR_LABELS = years
export const FUTURE_YEAR_LABELS = futureYears

export const FEET_LABELS = [
  {
    value: "3",
    label: "3 Feet"
  },
  {
    value: "4",
    label: "4 Feet"
  },
  {
    value: "5",
    label: "5 Feet"
  },
  {
    value: "6",
    label: "6 Feet"
  },
  {
    value: "7",
    label: "7 Feet"
  },
  {
    value: "8",
    label: "8 Feet"
  },
]

export const INCHES_LABELS = [
  {
    value: "01",
    label: "1 Inch",
  },
  {
    value: "02",
    label: "2 Inches"
  },
  {
    value: "03",
    label: "3 Inches",
  },
  {
    value: "04",
    label: "4 Inches"
  },
  {
    value: "05",
    label: "5 Inches",
  },
  {
    value: "06",
    label: "6 Inches"
  },
  {
    value: "07",
    label: "7 Inches",
  },
  {
    value: "08",
    label: "8 Inches"
  },
  {
    value: "09",
    label: "9 Inches",
  },
  {
    value: "10",
    label: "10 Inches"
  },
  {
    value: "11",
    label: "11 Inches",
  },
]

export const MONTH_CONVERSION = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December'
}

export const METRIC_LABELS = [
  {
    value: "form",
    label: "Form"
  },
  {
    value: "gait_velocity",
    label: "Gait Velocity"
  }
]

export const STATISTIC_LABELS = [
  {
    value: "min",
    label: "Minimum"
  },
  {
    value: "max",
    label: "Maximum"
  },
  {
    value: "avg",
    label: "Average"
  }
]

export const OPERATOR_LABELS = [
  {
    value: "==",
    label: "="
  },
  {
    value: "<",
    label: "<"
  },
  {
    value: ">",
    label: ">"
  },
  {
    value: "<=",
    label: "<="
  },
  {
    value: ">=",
    label: ">="
  }
]

export const MORE_OPERATOR_LABELS = [
  {
    value: "<",
    label: "<"
  },
  {
    value: "<=",
    label: "<="
  }
]
