import React, { Component } from "react";
import { SessionSimple } from "./SessionSimple";
import { RuleSimple } from "./RuleSimple";
import { get_progress } from "../../core/utils/session-collection-progress";
import { formatRules } from "../utils/FormatRules";

export class ComplianceBucket extends Component {
  constructor(props){
    super(props);
    this.state = {
      rules: formatRules(props.rules, props.evaluation),
    }
  }


  render() {
    return(
      <div className="overflow-auto h-full w-1/4">
        {/* Metrics Section */}
        <div className="w-full p-3 border-b-2">
          <label className="font-semibold text-center text-lg text-gray-700 block mb-2">
            SESSION METRICS
          </label>
          {Object.entries(this.props.metrics).length > 0 ? (
            Object.entries(this.props.metrics).map((block) => (
              <SessionSimple
                {...block}
                setGraph={this.props.setGraph}
                activeGraph={this.props.graph}
                key={block[0]}
              />
            ))
          ) : (
            <p className="text-gray-600 text-center">No metrics available</p>
          )}
        </div>

        {/* Rules Section */}
        <div className="w-full p-3 border-b-2">
          <label className="font-semibold text-center text-lg text-gray-700 block mb-2">
            RULES
          </label>
          {Object.entries(formatRules(this.props.rules, this.props.evaluation)).length > 0 ? (
            Object.entries(formatRules(this.props.rules, this.props.evaluation)).map((block) => (
              <RuleSimple
                {...block}
                setGraph={this.props.setGraph}
                activeGraph={this.props.graph}
                key={block[0]}
              />
            ))
          ) : (
            <p className="text-gray-600 text-center">No rules defined</p>
          )}
        </div>

        {/* Session Information Section */}
        <div className="w-full p-4">
          {/* Title */}
          <label className="font-semibold text-center text-lg text-gray-700 block mb-2">
            SESSION INFORMATION
          </label>

          {/* Bulleted List */}
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            {/* Start Date */}
            <li>
              <span className="font-medium">Start Date:</span> {this.props.startDate}
            </li>

            {/* Sets Required */}
            <li>
              <span className="font-medium">Sets Required:</span>{" "}
              {this.props.rules.min_threshold}
            </li>

            {/* Session Status */}
            <li className={Object.keys(this.props.evaluation).length === 0 ? "text-green-600" : "text-red-600"}>
              <span className="font-medium">Session Status:</span>{" "}
              {Object.keys(this.props.evaluation).length === 0
                ? "Compliant"
                : "Not Compliant"}
            </li>

            {/* Collection Progress */}
            {this.props.collectionProgress === 1 || this.props.collectionProgress === 2 ? (
              <li className="text-gray-600">
                Session is <span className="font-medium">under collected</span>
              </li>
            ) : null}

            {this.props.collectionProgress === 3 || this.props.collectionProgress === 4 ? (
              <li className="text-gray-600">
                Session is <span className="font-medium">over collected</span>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    )
  }
}