import React, { Component } from "react";
import { MONTH_LABELS, DATE_LABELS, YEAR_LABELS } from  '../../../resources/Config';
import { Dropdown } from '../../core/components/Dropdown';
import { DATA_KEY, ADD_MEDICATION_PATH, ADD_MEDICATION_TITLE } from '../../../resources/Vault';
import { createPostRequest } from '../../auth/utils/axios-utils';


export class AddMedication extends Component{
  constructor(props) {
    super(props);
    let d = new Date();
    this.state = {
      patientData: JSON.parse(sessionStorage.getItem(DATA_KEY)),
      name:"",
      start:"01/01/" + d.getFullYear(),
      startDay:"01",
      startMonth:"01",
      startYear: d.getFullYear(),
      end:"01/01/" + d.getFullYear(),
      endDay:"01",
      endMonth:"01",
      endYear: d.getFullYear(),
      error: false,
      opacity: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
    this.close = this.close.bind(this);
    this.setStartMonth = this.setStartMonth.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setStartYear = this.setStartYear.bind(this);
    this.setEndMonth = this.setEndMonth.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.setEndYear = this.setEndYear.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }

  /**
   * Function which checks for errors and then closes the modal
   * If we have no errors then submit, else show error message
   */
  checkErrors () {
    if(this.state.name == "" | 
      this.state.startDay == "" |
      this.state.startMonth == "" |
      this.state.startYear == "" |
      this.state.endDay == "" | 
      this.state.endMonth == "" | 
      this.state.endYear == ""
      ){
      this.setState({...this.state,error:true})
    } else {
      this.onSubmit()
    }
  }

  /**
   * 
   * @param {*} e 
   * Function which detects the clicking of the enter key
   * When enter is clicked, then we call the checkErrors method which submits the modal
   */
  handleKeypress(e) {
    if (e.charCode === 13) {
      this.checkErrors();
    }
  };

  /**
   * Function which submits the modals
   * We call the handle on close method of the parent component and close the modal
   */
  async onSubmit () {
    this.setState({...this.state, opacity: true});
    var payload = {};
    payload[this.state.name] = {
      startDate: this.state.start,
      endDate: this.state.end
    };

    // Configure the Add Medication request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const body = { 
      title: ADD_MEDICATION_TITLE, 
      info: {
        pid: this.state.patientData.pid
      },
      med: payload
    }

    try {
      const addMedicationResponse = await createPostRequest(ADD_MEDICATION_PATH, headers, body);
      this.setState({...this.state, opacity: false});
      this.props.updateMeds(addMedicationResponse);

      // Reset the state
      let year = new Date().getFullYear();
      this.setState({
        name:"",
        start: "01/01/" + year,
        end: "01/01/" + year,
        startDay:"01",
        startMonth:"01",
        startYear: year,
        endDay:"01",
        endMonth:"01",
        endYear: year,
        error: false
      });
    } catch(error) {
      this.setState({...this.state, opacity: false});
      console.log(error);
    }
  };

  /**
   * Function which closes the modal window
   * After closing the window, we reset state to default
   */
  close () {
    this.props.handleOnExit();
    let year = new Date().getFullYear();
    this.setState({
      name:"",
      start: "01/01/" + year,
      end: "01/01/" + year,
      startDay:"01",
      startMonth:"01",
      startYear: year,
      endDay:"01",
      endMonth:"01",
      endYear: year,
      error: false
    });
  }

  /**
   * Format date given input
   * @param {int} param 0=MM, 1=DD, 2=YYYY, 3-5: for end
   * @param {string} num Given number to format
   * @returns Formatted date string
   */
  formatDate(param, num) {
    let date;
    switch (param) {
      case 0:
        date = num + "/" + this.state.startDay + "/" + this.state.startYear;
        break;
      case 1:
        date = this.state.startMonth + "/" + num + "/" + this.state.startYear;
        break;
      case 2:
        date = this.state.startMonth + "/" + this.state.startDay + "/" + num;
        break;
      case 3:
        date = num + "/" + this.state.endDay + "/" + this.state.endYear;
        break;
      case 4:
        date = this.state.endMonth + "/" + num + "/" + this.state.endYear;
        break;
      case 5:
        date = this.state.endMonth + "/" + this.state.endDay + "/" + num;
        break;
    }
    return date;
  }

  setStartMonth (month) {
    this.setState({...this.state,
                  startMonth: month,
                  start: this.formatDate(0, month)});
  }
  setStartYear (year) {
    this.setState({...this.state,
                  startYear: year,
                  start: this.formatDate(2, year)});
  }
  setStartDate (date) {
    this.setState({...this.state,
                  startDay: date,
                  start: this.formatDate(1, date)});
  }
  setEndMonth (month) {
    this.setState({...this.state,
                  endMonth: month,
                  end: this.formatDate(3, month)});
  }
  setEndYear (year) {
    this.setState({...this.state,
                  endYear: year,
                  end: this.formatDate(5, year)});
  }
  setEndDate (date) {
    this.setState({...this.state,
                  endDay: date,
                  end: this.formatDate(4, date)});
  }

  render(){
    if (!this.props.visible) return null;
    return (
      <>
        <div className={"fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center " + (this.state.opacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
          <div className="bg-white w-1/4 rounded">
            <div className="sticky top-0 z-40 flex items-center border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2] p-2 relative">
              <label className="text-2xl font-bold pb-2 flex-grow text-center">
                Add Medication
              </label>
              <button 
                className="absolute right-4 px-3 py-1 text-xl font-semibold rounded-lg hover:bg-red-400"
                onClick={() => this.close()}>
                X
              </button>
            </div>
            <div className="relative p-4">
              <label className="font-medium text-gray-900">
                Medication Name
              </label>
              
              {this.state.error ? 
              <input
                onChange={event => this.setState({...this.state,name:event.target.value})}
                onKeyPress={this.handleKeypress}
                className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="Name"
              />
              :
              <input
                onChange={event => this.setState({...this.state,name:event.target.value})}
                onKeyPress={this.handleKeypress}
                className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="Name"
              />
              }
            </div>

            <div className="relative p-4">
              <label className="font-medium text-gray-900">
                Start Date
              </label>
              <div className="flex flex-row w-4/5">
                <div className="w-1/3">
                  <Dropdown
                    options={MONTH_LABELS} 
                    setValue={this.setStartMonth} />
                </div>
                <div className="w-1/3 mx-2">
                  <Dropdown
                    options={DATE_LABELS} 
                    setValue={this.setStartDate} />
                </div>
                <div className="w-1/3">
                  <Dropdown
                    options={YEAR_LABELS} 
                    setValue={this.setStartYear} />
                </div>
              </div>
            </div>

            <div className="relative p-4">
              <label className="font-medium text-gray-900">
                End Date
              </label>
              <div className="flex flex-row w-4/5">
                <div className="w-1/3">
                  <Dropdown
                    options={MONTH_LABELS} 
                    setValue={this.setEndMonth} />
                </div>
                <div className="w-1/3 mx-2">
                  <Dropdown
                    options={DATE_LABELS} 
                    setValue={this.setEndDate} />
                </div>
                <div className="w-1/3">
                  <Dropdown
                    options={YEAR_LABELS} 
                    setValue={this.setEndYear} />
                </div>
              </div>
            </div>
            {this.state.error ? <label className="font-semibold text-center text-red-400 ml-4">* Fields Cannot Be Blank</label> : null}
            <div className="p-4">
              <button 
                className= "mt-4 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
                onClick={this.checkErrors}>
                  Submit
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
}