import React, { Component } from "react";
import { withRouter } from 'react-router';
import axios from 'axios';
import { REMOVE_PATIENT_PATH, REMOVE_PATIENT_TITLE, AUTH_TOKEN_KEY, DATA_KEY, REMOVE_PATH } from '../../../resources/Vault';
import { ErrorToast }  from '../../core/components/ErrorToast';
import { SuccessToast } from "../../core/components/SuccessToast";
import { createDeleteRequest } from '../../auth/utils/axios-utils';

class RemovePatient extends Component{
  
  constructor(props) {
    super(props);
    this.state = {
      password:"",
      error: false,
      incorrectPassword: false,
      showAPIErrorToast: false,
      showAPISuccessToast: false,
      opacity: false,
    };
    this.handleKeypress = this.handleKeypress.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.close = this.close.bind(this);
    this.hideErrToast = this.hideErrToast.bind(this);
    this.hideSuccToast = this.hideSuccToast.bind(this);
  }

  /**
    * Hides success toast
    */
  hideSuccToast() {
    this.setState({...this.state, showAPISuccessToast: false});
  }

  /**
    * Hides error toast
    */
  hideErrToast() {
    this.setState({...this.state, showAPIErrorToast: false});
  }

  handleOnClose () {
    this.setState({...this.state,showModal: false})
  };
  
  /**
   * Function which checks for errors and then closes the modal
   * If we have no errors then submit, else show error message
   */
  checkErrors () {
    if(this.state.password == ""){
      this.setState({...this.state,error:true})
    } else {
      this.onSubmit()
    }
  }
  handleKeypress(e) {
    if (e.charCode === 13) {
      this.checkErrors();
    }
  };

  /**
   * Function which calls the BE and  submits the modals
   * We call the handle on close method of the parent component and close the modal
   */
  async onSubmit () {
    this.setState({...this.state, opacity: true});
    // Configure the remove patient request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const data = {
      title: REMOVE_PATIENT_TITLE,
      info: {
        pid: this.props.pid,
        password: this.state.password
      }
    };

    try {
      const removePatientResponse = await createDeleteRequest(REMOVE_PATIENT_PATH, headers, data);
      // Modal clean up
      this.setState({...this.state, opacity: false});
      this.props.removePatient(this.state);
      this.setState({
        ...this.state,
        password: "",
        error: false,
        showAPIErrorToast: false,
        showAPISuccessToast: true,
      });

      // Remove the patient on the portal
      sessionStorage.removeItem(DATA_KEY);
      var path = REMOVE_PATH + this.props.pid.toString();
      this.props.history.replace({pathname: path});
    } catch(error) {
      console.log(error);
      this.setState({
        ...this.state, 
        opacity: false,
        password: "",
        error: false,
        incorrectPassword: true,
        showAPIErrorToast: true,
        showAPISuccessToast: false,
      });
    }
  };

  /**
   * Function which closes the modal window
   * After closing the window, we reset state to default
   */
  close () {
    this.props.handleOnExit();
    this.setState({
      password: "",
      error: false,
      incorrectPassword: false
    })
  }

  render(){
    if (!this.props.visible) return null;
    return (
      <>
        <div className={"fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center " + (this.state.opacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
          <div className="bg-white w-1/4 rounded">
            <div className="sticky top-0 z-40 flex items-center border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2] p-2 relative">
              <label className="text-2xl font-bold pb-2 flex-grow text-center">
                Remove Patient
              </label>
              <button 
                className="absolute right-4 px-3 py-1 text-xl font-semibold rounded-lg hover:bg-red-400"
                onClick={() => this.close()}>
                X
              </button>
            </div>
            <div className="relative p-4">
              <div className="flex justify-center">
                <label className="justify-center font-medium text-gray-900">
                  Enter your password to confirm
                </label>
              </div>
              {this.state.error ?
              <input
                onChange={event => this.setState({...this.state,password:event.target.value})}
                onKeyPress={this.handleKeypress}
                type="password"
                className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="Password"
              />
              :
              <input
                onChange={event => this.setState({...this.state,password:event.target.value})}
                onKeyPress={this.handleKeypress}
                type="password"
                className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="Password"
              />
              }
              {this.state.incorrectPassword ? <label className="font-semibold text-center text-red-400">* Incorrect Password</label> : null}
            </div>

            <div className="p-4">
              <button 
                className= "mt-4 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
                onClick={this.checkErrors}>Remove
              </button>
            </div>
            <div className="absolute bottom-0 right-0 mr-10 mb-6">
              {this.state.showAPIErrorToast ? <ErrorToast message={"There was an issue removing this patient"} visible={this.state.showAPIErrorToast} hide={this.hideErrToast}/> : null}
              {this.state.showAPISuccessToast ? <SuccessToast message={"Patient removed"} visible={this.state.showAPISuccessToast} hide={this.hideSuccToast}/> : null}
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(RemovePatient);
