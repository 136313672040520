import React from 'react';


/**
 * Simple medication visual displaying name, start and end dates
 * @param {Object} props Medication Object
 * @returns Medication component displaying props
 */
function Medication(props) {
  return(
    <div className="w-full flex flex-col p-2 text-center">
      <label className="text-xl font-bold">{props.name}</label>
      <div className="">
        <label className=""> Start Date:</label>
        <label className=""> {props.start} </label>
      </div>
      <div className="">
        <label className=""> End Date:</label>
        <label className=""> {props.end} </label>
      </div>
    </div>
  )
}

export default Medication;
