import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { cardio } from 'ldrs';

// Custom Components
import TabBar from '../components/TabBar';
import { Dropdown } from '../../core/components/Dropdown';
import DateSelection from '../../core/components/DateSelection';
import Graph from '../components/charts/Graph';
import ViewGranularData from '../modals/ViewGranularData';

//Custom Utils and Functions
import { createGetRequest } from '../../auth/utils/axios-utils';
import { sortDates, updateAggData, updatePatientMSKData, formatDatesForDropdown, formatForHistogram } from '../utils/respiratory-data-helpers';

// MSK Graphs for dropdown
// TODO: Export these to a config file
const mskGraphs = [
  {
    value: "formOverTime",
    label: "Form Graph"
  },
  {
    value: "classificationConfidence",
    label: "Classification Confidence"
  },
  {
    value: "tripCount",
    label: "Trip Count"
  },
  {
    value: "accDistribution",
    label: "Movement Plane Distribution"
  },
  {
    value: "gaitVelocity",
    label: "Gait Velocity"
  }
];

class MusculoskeletalData extends Component {
  constructor(props) {
    super(props);
    // Access query params from the location object
    const queryParams = new URLSearchParams(this.props.location.search);
    this.state = {
      period: queryParams.get('period'),
      pid: this.props.match.params.pid,
      data: [],
      dates: [],
      activeDate: '',
      aggData: '',
      peakFlowInput: '',
      peakFlowError: false,
      graph: 'formOverTime',
      graphTitle: 'Form Graph',
      loadingAPIData: true,
      showGranularDataModal: false,
      granularData: '',
      granularDataDate: '',
      granularDataFlagged: '',
      notes: []
    }
    this.setDate = this.setDate.bind(this);
    this.changeGraph = this.changeGraph.bind(this);
    this.renderList = this.renderList.bind(this);
    this.showGranularData = this.showGranularData.bind(this);
    this.closeGranularDataModal = this.closeGranularDataModal.bind(this);

    // Register the loading animation
    cardio.register();
  };

  async componentDidMount() {
    // Configure the API request
    const headers = { "x-access-token": localStorage.getItem("auth_token")};
    const getAggDatesPath = '/getAggDates/' + this.state.pid;
    const getNotesPath = "/getNotesByTag/" + this.state.pid + '/data';
    try {
      const getAggDatesResponse = await createGetRequest(getAggDatesPath, headers);
      // If the patient has no data, dont call anymore APIs
      if(getAggDatesResponse.length === 0) {
        this.setState({...this.state, 
          loadingAPIData: false
        });
        return;
      }
      let dataNotes = await createGetRequest(getNotesPath, headers);
      let dates = sortDates(getAggDatesResponse);
      let aggData = await updateAggData(dates[0], this.state.pid);
      let data = await updatePatientMSKData(this.state.pid, dates[0]);
      this.setState({...this.state, 
        dates: dates,
        activeDate: dates[0],
        aggData: aggData,
        data: data,
        loadingAPIData: false,
        notes: dataNotes
      });
    } catch(error) {
      console.log(error);
    }
  }

  /**
   * Sets the active date and updates data accordingly
   * @param {string} date Date 
   */
  async setDate(date) {
    this.setState({...this.state, loadingAPIData: true});
    let newAggData = await updateAggData(date, this.state.pid);
    let newPatientData = await updatePatientMSKData(this.state.pid, date);
    this.setState({...this.state,
      aggData: newAggData,
      data: newPatientData,
      activeDate: date,
      loadingAPIData: false
    });
  }

  /**
   * Changes the graph to display
   * @param {string} graph Graph to display
   */
  changeGraph(graph) {
    if(graph === 'formOverTime') {
      this.setState({...this.state, graph: graph, graphTitle: 'Form Graph'});
    } else if(graph === 'classificationConfidence') {
      this.setState({...this.state, graph: graph, graphTitle: 'Classification Confidence'});
    } else if(graph === 'tripCount') {
      this.setState({...this.state, graph: graph, graphTitle: 'Trip Count'});
    } else if(graph === 'accDistribution') {
      this.setState({...this.state, graph: graph, graphTitle: 'Movement Plane Distribution'});
    } else if(graph === 'gaitVelocity') {
      this.setState({...this.state, graph: graph, graphTitle: 'Gait Velocity'});
    }
  }

  renderList() {
    const renderedItems = [];
    for (const key in this.state.aggData.balance_dates) {
      const value = this.state.aggData.balance_dates[key];
      renderedItems.push(
        <li key={key} className="flex flex-row justify-center items-center w-full py-2 px-4 border-b-2 border-gray-200">
          <div className="w-1/2 text-left">{key}</div>
          <div className="w-1/2 text-right">{value}</div>
        </li>
      );
    }
    return renderedItems;
  }
  
  showGranularData(date, data, flagged) {
    this.setState({...this.state,
      showGranularDataModal: true,
      granularData: data,
      granularDataDate: date,
      granularDataFlagged: flagged
    });
    // more logic to open the modal
  }
  closeGranularDataModal() {
    this.setState({...this.state, showGranularDataModal: false});
  }

  render() {
    const { data, loadingAPIData, graph } = this.state;
    const hasEnoughData = data && data.length >= 3;

    // Conditional rendering
    let content;

    if (loadingAPIData) {
      // Loading widget
      content = (
        <div className="w-full flex justify-center mt-10 mb-6">
          <l-cardio size="80" stroke="6" speed="2" color="black" />
        </div>
      );
    } else if (!hasEnoughData) {
      // Message for not enough data
      content = (
        <div className="flex flex-col h-screen w-full pl-4 mt-10">
          <label className="text-6xl font-extrabold text-primary text-center">
            Not Enough Data Collected To Show This Graph
          </label>
          <div className="text-2xl text-center text-gray-500 mt-6">
            Collect some data to view graphs
          </div>
        </div>
      );
    } else {
      // Graph rendering
      content = <Graph
        graphSet={mskGraphs}
        data={data}
        graph={graph}
        showGranularData={this.showGranularData}/>;
    }

    return (
      <section>
        <TabBar activeTab={"msk"}/>

        {/* Main Section for the MSK data page */}
        <section className="flex flex-row h-screen w-full p-4">
          
          {/* Section for the aggregate data */}
          <section className="flex flex-col w-1/5 mr-2 mb-16 border-4 border-black overflow-auto">
            {/* Title Section */}
            <div className="flex flex-row w-full items-center justify-between border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2]">
              <label className="text-2xl font-bold pb-2 flex-grow text-center">
                View Aggregate Data
              </label>
            </div>

            {/* Date Selector */}
            <div className="flex flex-row mt-2 pl-4">
              <h1 className="text-primary text-center mt-2 text-xl font-bold mb-2">
                Select Month:
              </h1>
              <div className="w-1/2 ml-4">
                <DateSelection
                  dates={formatDatesForDropdown(this.state.dates)}
                  setDate={this.setDate}
                  loading={this.state.loadingAPIData}
                />
              </div>
            </div>

            {/* Graph Selector */}
            <div className="flex flex-row mt-2 pl-4">
              <h1 className="text-primary text-center mt-2 text-xl font-bold mb-2">
                Select Graph:
              </h1>
              <div className="w-1/2 ml-4">
                <Dropdown
                  options={mskGraphs}
                  setValue={this.changeGraph}
                  disabled={this.state.dates.length === 0}
                />
              </div>
            </div>

            {/* Total Observations Section */}
            <div className="flex flex-col border-t-2 border-black mt-4 w-full items-center">
              {/* Title Section */}
              <div className="flex flex-row w-full items-center justify-between border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2] py-2">
                <label className="text-2xl font-bold text-center w-full">
                  {this.state.aggData.count} Sets Collected For {this.state.activeDate}
                </label>
              </div>

              {/* Information Section */}
              <div className="flex flex-row w-full justify-center bg-gray-100 py-2 px-4 border-b-2 border-gray-300">
                <div className="w-1/2 text-left text-lg font-semibold">Day</div>
                <div className="w-1/2 text-right text-lg font-semibold">Sets Collected</div>
              </div>

              {/* Container for the sets collected data */}
              <ul className="w-full font-bold space-y-2 text-base text-center">
                {this.renderList()}
              </ul>
            </div>

          </section>

          {/* Section for the graphs */}
          <section className="flex flex-col w-4/5 ml-2 mb-16 border-4 border-black">
            {/* Graphs */}
            <div className="flex flex-row w-full py-4 items-center justify-between border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2]">
              <label className="text-2xl font-bold pb-2 flex-grow text-center">
                {this.state.graphTitle}
              </label>
            </div>

            {/* Content contains either the graphs, loading animation or data message */}
            <>{content}</>

          </section>
        </section>
        {/* TODO: Hook up notes to show in the granular data modal */}
        <ViewGranularData
          visible={this.state.showGranularDataModal}
          closeModal={this.closeGranularDataModal}
          data={this.state.granularData}
          date={this.state.granularDataDate}
          flagged={this.state.granularDataFlagged}
          pid={this.state.pid}
          notes={this.state.notes} />
      </section>
    )
  }
};

export default withRouter(MusculoskeletalData);