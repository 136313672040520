import { createPostRequest, createGetRequest } from "../../auth/utils/axios-utils";
import { GET_AGGREGATE_DATA_PATH, GET_RESP_AGGREGATE_DATA_PATH, GET_AGGREGATE_DATA_TITLE } from '../../../resources/Vault';
import { MONTH_CONVERSION } from '../../../resources/Config';

/**
   * Sorts dates by most recent
   * @param {Array<string>} dates Array of dates 
   * @returns Sorted array of dates
   */
export function sortDates(dates) {
    dates.sort(function (a, b) {
        if (a < b) {
            return 1;
        } else if (a === b) {
            return 0;
        } else {
            return -1;
        }
    });
    return dates;
}

/**
 * Updates the aggregate data from the backend
 * @param {string} date Date
 */
export async function updateAggData(date, pid) {
    // Configure the API request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const info = {
        pid: pid,
        date: date
    };
    const body = { title: GET_AGGREGATE_DATA_TITLE, info: info }

    try {
        const updateAggDataResponse = await createPostRequest(GET_AGGREGATE_DATA_PATH, headers, body);
        return (updateAggDataResponse);
    } catch(error) {
        console.log(error);
    }
}

export async function updateRespAggData(date, pid) {
    // Configure the API request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const info = {
        pid: pid,
        date: date
    };
    const body = { title: GET_AGGREGATE_DATA_TITLE, info: info }

    try {
        const updateAggDataResponse = await createPostRequest(GET_RESP_AGGREGATE_DATA_PATH, headers, body);
        return (updateAggDataResponse);
    } catch(error) {
        console.log(error);
    }
}

/**
 * Get the patient data for a month from the backend
 * @param {string} date Date
 */
export async function updatePatientRespiratoryData(pid, date) {
    // Configure the API request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const path = `/getRespData/${pid}/${date}`;

    try {
        const getDataResponse = await createGetRequest(path, headers);
        return(formatExerciseData(getDataResponse));
    } catch(error) {
        console.log(error);
    }
}

/**
 * Get the patient data for a month from the backend
 * @param {string} date Date
 */
export async function updatePatientMSKData(pid, date) {
    // Configure the API request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const path = `/getMskData/${pid}/${date}`;

    try {
        const getDataResponse = await createGetRequest(path, headers);
        return(formatExerciseData(getDataResponse));
    } catch(error) {
        console.log(error);
    }
}

/**
 * Formats exercise data from the database and sorts it
 * @param {Array<Object>} data Exercise data
 * @returns Properly formatted and sorted data
 */
function formatExerciseData(data) {
    data = formatDates(data);
    data.sort(function (a, b) {
        if (a.date < b.date) {
            return -1;
        } else if (a.date === b.date) {
            return 0;
        } else {
            return 1;
        }
    });
    return data;
}

  /**
   * Turns date strings into Date objects
   * @param {Array<Object>} data Patient data 
   * @returns Patient data with objectified dates
   */
function formatDates(data) {
    for (var i=0; i<data.length; i++) {
        data[i].date = Date.parse(data[i].date);
    }
    return data;
}

export function formatDatesForDropdown(dates) {
    let dropdownItems = [];
    if (dates.length > 0) {
        dates.forEach(item => {
            let temp = item.split('-');
            let month;
            let year = temp[1];

            // Get text for the month
            month = MONTH_CONVERSION[temp[0]];

            // Create a dropdown entry
            dropdownItems.push({
                value: item,
                label: `${month} ${year}`
            });
        });
    }
    return dropdownItems;
}

/**
   * Filters out data that is respiratory related
   * @param {Array<Object>} data Exercise Data Array
   * @returns Filtered array of Objects
   */
export function formatForHistogram(data) {
    if(data == undefined) {
        return
    }
    let hist_data = [];
    for (var i=0; i < data.length; i++) {
        var tmp = data[i];
        // Ensure that the dataset is relevant
        if(tmp.exercise !== 'sumo') continue;
        hist_data.push({ y: tmp.shake_count });
    }
    return hist_data;
}

export async function addPeakFlowData(data, pid) {
    // Configure the API request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const path = `/addPeakflow/${pid}`;
    const body = {"lpm": data};

    try {
        const addPeakflowResponse = await createPostRequest(path, headers, body);
        return addPeakflowResponse;
    } catch(error) {
        console.error(error);
    }
}