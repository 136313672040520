import React, { Component } from 'react';
import { withRouter } from 'react-router';

class ComplianceTriageObject extends Component {
  constructor(props) {
    super(props);
    this.getEndDate = this.getEndDate.bind(this);
    this.getColorStyles = this.getColorStyles.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.routeToSession = this.routeToSession.bind(this);
  }

  /**
   * Formats a datestring to calculate end date of a session
   * @param {string} startDate Session start date
   * @param {number} daysAfter Session period
   * @returns Formatted End-Date string
   */
  getEndDate(startDate, daysAfter) {
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + (daysAfter - 1));
    return `${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()}`;
  }

  /**
   * Formats the start date
   * @param {string} date Date string 
   * @returns Formatted Date String
   */
  formatDate(date) {
    const dateObj = new Date(date);
    return `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`;
  }

  /**
   * Determines the styles based on compliance data
   * @returns Object containing color styles and hover styles
   */
  getColorStyles() {
    if (Object.keys(this.props.evaluation).length > 0) {
      return {
        baseColor: "bg-red-300",
        hoverColor: "hover:bg-red-400",
        borderColor: "hover:border-red-500",
      };
    } else {
      return {
        baseColor: "bg-green-300",
        hoverColor: "hover:bg-green-400",
        borderColor: "hover:border-green-500",
      };
    }
  }

  routeToSession() {
    if (this.props.pid) {
      const path = `/portal/patient-data/${this.props.pid}/sessions?sid=${this.props.id}`;
      this.props.history.push(path);
    }
  }

  render() {
    const { baseColor, hoverColor, borderColor } = this.getColorStyles();

    return (
      <div
        className={`cursor-pointer rounded-lg w-full p-5 mt-4 font-bold text-xs space-y-2 ${baseColor} ${hoverColor} ${borderColor} shadow-xl`}
        onClick={this.routeToSession}
      >
        <div className="flex justify-center items-center">
          <label className="font-bold">{this.props.name}</label>
        </div>
        <div className="flex justify-center items-center">
          <label>Start Date: {this.formatDate(this.props.start_date)}</label>
        </div>
        <div className="flex justify-center items-center">
          <label>End Date: {this.getEndDate(this.props.start_date, this.props.rules.period)}</label>
        </div>
        <div className="flex justify-center items-center text-center">
          <label>Session Name: {this.props.rules.title}</label>
        </div>
      </div>
    );
  }
}

export default withRouter(ComplianceTriageObject);
