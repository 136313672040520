import React, { Component } from "react";
import { ErrorToast }  from '../../core/components/ErrorToast';
import { SuccessToast } from '../../core/components/SuccessToast';
// Redux imports
import { connect } from 'react-redux';
import { setAddUserModalVisible, addUser, updateNewUserEmail, setAddUserFailureToast, setAddUserSuccessToast } from '../redux/usersSlice';

class AddUser extends Component{
  constructor(props) {
    super(props);
    this.handleKeypress = this.handleKeypress.bind(this);
    this.hideErrToast = this.hideErrToast.bind(this);
    this.hideSuccToast = this.hideSuccToast.bind(this);
  };

  /**
   * 
   * @param {*} e 
   * Function which detects the clicking of the enter key
   * When enter is clicked, then we call the checkErrors method which submits the modal
   */
  handleKeypress(e) {
    const { newUserEmail } = this.props;
    if (e.charCode === 13) {
      this.props.addUser({ newUserEmail });
    }
  };

  /**
    * Hides success toast
    */
  hideSuccToast() {
    this.props.setAddUserSuccessToast(false);
  }

  /**
   * Hides error toast
   */
  hideErrToast() {
    this.props.setAddUserFailureToast(false);
  }

  render(){
    const { showAddUserModal, setAddUserModalVisible, showAddUserSuccessToast, showAddUserFailureToast, addUserOpacity, newUserEmail } = this.props;
    if (!showAddUserModal) return null;
    return (
      <>
        <div className={"fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center " + (addUserOpacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
          <div className="bg-white p-4 rounded">
            <div className="flex justify-center">
              <h1 
                className="font-semibold text-center text-xl text-gray-700 mx-16"
                >
                Add User
              </h1>
              <button 
                className="font-semibold text-center text-xl text-gray-700 w-7 rounded-xl hover:bg-red-400"
                onClick={() => setAddUserModalVisible(false)}>
                X
              </button>
            </div>

            <div className="relative">
              <label className="font-medium text-gray-900">
                User Email
              </label>
              <input
                value={newUserEmail}
                onChange={event => this.props.updateNewUserEmail(event.target.value)}
                onKeyPress={this.handleKeypress}
                className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="Email"
              />
            </div>

            <button 
              className= "mt-4 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
              onClick={() => this.props.addUser({ newUserEmail })}>
                Submit
            </button>
          </div>
          <div className="absolute bottom-0 right-0 mr-10 mb-6">
            {showAddUserFailureToast ? <ErrorToast message={"There was an issue adding this user"} visible={showAddUserFailureToast} hide={this.hideErrToast}/> : null}
            {showAddUserSuccessToast ? <SuccessToast message={"User has been added"} visible={showAddUserSuccessToast} hide={this.hideSuccToast}/> : null}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  users: state.users.users,
  showAddUserModal: state.users.showAddUserModal,
  newUserEmail: state.users.newUserEmail,
  showAddUserFailureToast: state.users.showAddUserFailureToast,
  showAddUserSuccessToast: state.users.showAddUserSuccessToast,
  addUserOpacity: state.users.addUserOpacity
});
const mapDispatchToProps = {
  setAddUserModalVisible,
  updateNewUserEmail,
  addUser,
  setAddUserFailureToast,
  setAddUserSuccessToast
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);