import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { BiDotsVerticalRounded } from "react-icons/bi";
import { cardio } from 'ldrs';
import { FaSortUp, FaSortDown } from "react-icons/fa";

// Custom Components
import TabBar from '../components/TabBar';
import { PopOverMenu } from '../../core/components/PopOverMenu';
import Medication from '../components/Medication';
import ComplianceTriageObject from '../../portal/components/ComplianceTriageObject';
import NoteSimple from '../components/NoteSimple';
import AddNote from '../modals/AddNote';
import { AddMedication } from '../modals/AddMedication';
import RemovePatient from '../modals/RemovePatient';
import EditPatient from '../modals/EditPatient';
import FilterMenu from '../components/FilterMenu';

// Data and Utils
import { INFO_KEY, LOGIN_PAGE_PATH, PERMISSIONS, GET_PATIENT_PATH, PORTAL_PAGE_PATH } from '../../../resources/Vault';
import { createGetRequest } from '../../auth/utils/axios-utils';
import { getMonitoringSessions } from '../../../resources/url_construction';
import formatData from '../utils/format-patient-data';
import { getFilters, getFilteredNotes } from '../utils/filter-functions';


class PatientOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pid: this.props.match.params.pid,
      medications: [],
      sessions: [],
      notes: [],
      originalNoteList: [],
      isLoading: true,
      patientData: {},
      info: JSON.parse(localStorage.getItem(INFO_KEY)),
      popOverMenuItems: [
        {title: "Edit Patient", onClick: this.showEditPatientModal},
        {title: "Remove Patient", onClick: this.showRemovePatientModal},
        {title: "Add Medication", onClick: this.showAddMedicationModal}
      ],
      showPopOverMenu: false,
      showEditPatientModal: false,
      showRemovePatientModal: false,
      showAddNoteModal: false,
      showAddMedicationModal: false,
      sortOrder: "asc",
      selectedFilters: {}
    };
    // Binding Functions
    this.showEditPatientModal = this.showEditPatientModal.bind(this);
    this.showRemovePatientModal = this.showRemovePatientModal.bind(this);
    this.showAddMedicationModal = this.showAddMedicationModal.bind(this);
    this.handleClosePopOverMenu = this.handleClosePopOverMenu.bind(this);
    this.addNote = this.addNote.bind(this);
    this.updateMeds = this.updateMeds.bind(this);
    this.removePatient = this.removePatient.bind(this);
    this.editPatientData = this.editPatientData.bind(this);

    // Create the button ref
    this.buttonRef = React.createRef();
    // Register the loading animation
    cardio.register();
  };

  async componentDidMount() {
    document.title = "Patient Data | Patient Management Portal";
    // Configure the API requests
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const sessionsPath = getMonitoringSessions(this.state.pid);
    const getNotesPath = "/getNotes/" + this.state.pid;

    try {
      // Call the get patient data, get notes, and get monitoring session endpoints
      const getPatientResponse = await createGetRequest(GET_PATIENT_PATH + this.state.pid, headers);
      const getMonitoringSessionsResponse = await createGetRequest(sessionsPath, headers);
      const getNotesResponse = await createGetRequest(getNotesPath, headers);
      this.setState({...this.state,
        patientData: getPatientResponse,
        sessions: getMonitoringSessionsResponse,
        notes: getNotesResponse,
        originalNoteList: getNotesResponse,
        isLoading: false
      });
      // If the patient has medication data then add it 
      if(getPatientResponse.medication) {
        var keys = [];
        Object.entries(getPatientResponse.medication).map(([key,value]) => keys.push(key));
        keys.forEach((key) => {
          var temp = {
            name: key,
            start: getPatientResponse.medication[key].startDate,
            end: getPatientResponse.medication[key].endDate
          };
          this.state.medications.push(temp);    
        });
      }
    } catch (error) {
      console.log(error);
      this.props.history.push({pathname: PORTAL_PAGE_PATH});
    }
  };

  showEditPatientModal = () => {
    this.setState({...this.state,showEditPatientModal: true, showPopOverMenu: false});
  };
  showRemovePatientModal = () => {
    this.setState({...this.state,showRemovePatientModal: true, showPopOverMenu: false});
  };
  showAddMedicationModal = () => {
    this.setState({...this.state,showAddMedicationModal: true, showPopOverMenu: false});
  };
  handleClosePopOverMenu () {
    this.setState({...this.state,showPopOverMenu: false});
  }

  // One function which will close each modal
  handleCloseModal = () => {
    this.setState({...this.state,
      showEditPatientModal: false,
      showRemovePatientModal: false,
      showAddNoteModal: false,
      showAddMedicationModal: false,
    });
  }

  // Function which adds a new note to state
  addNote(note) {
    let updatedNotes = [...this.state.originalNoteList, note]
    this.setState({...this.state,
      showAddNoteModal: false,
      notes: getFilteredNotes(updatedNotes, this.state.selectedFilters),
      originalNoteList: [...this.state.originalNoteList, note]
    });
  }

  updateMeds(med) {
    let updatedMedicationList = this.state.medications;
    updatedMedicationList.push(med);

    this.setState({...this.state, 
      showAddMedicationModal: false,
      medications: updatedMedicationList,
    });
  }

  removePatient() {
    this.setState({...this.state, 
      showRemovePatientModal: false,
    });
    const path = '/portal';
    this.props.history.push(path);
  }

  togglePopOverMenu = () => {
    this.setState((prevState) => ({
      showPopOverMenu: !prevState.showPopOverMenu
    }));
  };

  getHeightString() {
    let feet = Math.floor(this.state.patientData.height / 12);
    let inches  = this.state.patientData.height % 12;
    return(feet + " ft. " + inches + " in.")
  }

  editPatientData(updatedPatient, note) {
    let patientData = this.state.patientData;
    patientData.firstName=updatedPatient.firstName;
    patientData.lastName=updatedPatient.lastName;
    patientData.height=updatedPatient.height;
    patientData.weight=updatedPatient.weight;
    patientData.dob=updatedPatient.dob;

    this.setState({...this.state, 
      showEditPatientModal: false,
      notes: [...this.state.originalNoteList, note],
      originalNoteList: [...this.state.originalNoteList, note]
    });
  }

  filterNotes = (selectedFilters) => {
    this.setState({...this.state,
      notes: getFilteredNotes(this.state.originalNoteList, selectedFilters),
      selectedFilters: selectedFilters
    });
  };

   // Toggle sorting order and sort notes
   toggleSort = () => {
    const { notes, sortOrder } = this.state;
    const newOrder = sortOrder === "asc" ? "desc" : "asc";

    const sortedNotes = [...notes].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return newOrder === "asc" ? dateA - dateB : dateB - dateA;
    });

    this.setState({ notes: sortedNotes, sortOrder: newOrder });
  };

  render() {
    const filters = getFilters(this.state.originalNoteList);
    const { notes, sortOrder } = this.state;
    return (
      <section>
        <TabBar activeTab={"patient"}/>

        {/* Main Section for the patient overview page */}
        <section className="flex flex-row h-screen w-full p-4">
          
          {/* Section for the patient information */}
          <section className="flex flex-col w-1/5 mr-2 mb-16 border-4 border-black overflow-auto">
            {/* Patient Name and Info Section */}
            <div className="flex flex-row w-full items-center justify-between border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2]">
              <label className="text-3xl font-bold pb-2 flex-grow text-center -mr-6">
                {this.state.patientData.firstName + " " + this.state.patientData.lastName}
              </label>
              <div ref={this.buttonRef}>
                <BiDotsVerticalRounded 
                  className="w-6 cursor-pointer text-xl font-bold"
                  onClick={this.togglePopOverMenu}
                />
              </div>
            </div>
            <PopOverMenu
              showMenu={this.state.showPopOverMenu}
              handleOnClose={this.handleClosePopOverMenu}
              menuItems={this.state.popOverMenuItems}
              buttonRef={this.buttonRef}
            />

            {/* Patient Information Section */}
            {this.state.isLoading ?
              <div className="w-full flex justify-center mt-10 mb-6">
                <l-cardio size="80" stroke="6" speed="2" color="black" />
              </div> 
              :
              <div className="w-full flex flex-col mt-2 ml-2">
                <div className="w-full flex flex-row mb-2">
                  <label className="text-xl">
                    Name:
                  </label>
                  <label className="text-xl font-bold ml-2">
                    {this.state.patientData.firstName + " " + this.state.patientData.lastName}
                  </label>
                </div>
                <div className="w-full flex flex-row mb-2">
                  <label className="text-xl">
                    Date Of Birth:
                  </label>
                  <label className="text-xl font-bold ml-2">
                    {this.state.patientData.dob}
                  </label>
                </div>
                <div className="w-full flex flex-row mb-2">
                  <label className="text-xl">
                    Height:
                  </label>
                  <label className="text-xl font-bold ml-2">
                    {this.getHeightString()}
                  </label>
                </div>
                <div className="w-full flex flex-row mb-2">
                  <label className="text-xl">
                    Weight:
                  </label>
                  <label className="text-xl font-bold ml-2">
                    {this.state.patientData.weight + " lbs."}
                  </label>
                </div>
                <div className="w-full flex flex-row mb-2">
                  <label className="text-xl">
                    Sex:
                  </label>
                  <label className="text-xl font-bold ml-2">
                    {this.state.patientData.sex}
                  </label>
                </div>
              </div>
            }

            {/* Medication Section */}
            <div className="flex flex-col border-b-2 border-t-2 border-black">
              <label className="text-2xl font-bold pb-2 flex-grow text-center border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2]">
                Patient Medications
              </label>
              {this.state.isLoading &&
                <div className="w-full flex justify-center mt-10 mb-6">
                  <l-cardio size="80" stroke="6" speed="2" color="black" />
                </div> 
              }
              {!this.state.isLoading && this.state.medications.length === 0 ?
                <div className="w-full flex-col text-center my-4">
                  <label className="text-2xl">
                    No Mediactions For This Patient
                  </label>
                </div>
                :
                <div>
                  {this.state.medications.map(block => Medication(block))}
                </div>
              }
            </div>

            {/* Active Sessions Section */}
            <div className="flex flex-col w-full">
              <label className="text-2xl font-bold pb-2 flex-grow text-center border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2]">
                Session History
              </label>
              {this.state.isLoading &&
                <div className="w-full flex justify-center mt-10">
                  <l-cardio size="80" stroke="6" speed="2" color="black" />
                </div> 
              }
              {!this.state.isLoading && this.state.sessions.length === 0 ?
                <div className="w-full flex-col items-center mt-4">
                  <div className="text-center">
                    <label className="text-2xl">
                      No Sessions For This Patient
                    </label>
                  </div>
                  <div className="text-center h-16 mt-4">
                    <button
                      className="w-3/5 h-full rounded-md bg-primary hover:bg-accent text-white"
                      onClick={() => this.props.history.push('/portal/patient-data/'  + this.state.pid.toString() + '/sessions')}>
                        Add Session
                    </button>
                  </div>
                </div> 
                :
                <div className="px-2">
                  {this.state.sessions && this.state.sessions.map(block => 
                    <ComplianceTriageObject
                      key={block.rules.title}
                      pid={this.state.pid}
                      id={block.id}
                      {...block} />
                  )}
                </div>
              }
            </div>
          </section>

          {/* Section for the notes */}
          <section className="flex flex-col w-4/5 ml-2 mb-16 border-4 border-black">
            <div className="flex flex-col bg-[#E5E4E2] rounded">
              {/* Patient Notes Title */}
              <div className="text-center justify-center mt-4">
                <label className="text-3xl font-bold">
                  Patient Notes
                </label>
              </div>

              {/* Info and buttons */}
              <div className="w-full flex flex-row mt-8">
                {/* Info */}
                <div className="w-1/2 flex flex-col ml-4">
                  <label className="text-2xl font-bold">View Patient Notes</label>
                  <label className="text-md">Add a new note manually or filter by note type</label>
                </div>

                {/* Buttons */}
                <div className="w-1/2 flex flex-row justify-end">
                  <div className="w-2/5 h-full mr-4">
                    <FilterMenu filters={filters} filterNotes={this.filterNotes} />
                  </div>
                  <button
                    className="w-2/5 h-full mx-4 rounded-md bg-primary hover:bg-accent text-white"
                    onClick={() => this.setState({...this.state,showAddNoteModal: true})}>
                      Add Note
                  </button>
                </div>
              </div>

              {/* Outline sections which describes values below */}
              <div className="mt-7 flex flex-row w-full border-b-4 border-[#D3D3D3] shadow-lg rounded">
                <div className="w-3/5 flex flex-row ml-4">
                  <div className="w-1/4">
                    <label className="text-lg mr-2">Date</label>
                    {/* Icon to sort notes */}
                    <button
                      onClick={this.toggleSort}
                      aria-label="Sort by date"
                      className="text-gray-600 hover:text-gray-800 focus:outline-none"
                    >
                      {sortOrder === "asc" ? (
                        <FaSortUp className="w-6 h-6" />
                      ) : (
                        <FaSortDown className="w-6 h-6" />
                      )}
                    </button>
                  </div>
                  <div className="w-1/4">
                    <label className="text-lg">Author</label>
                  </div>
                  <div className="w-1/4">
                    <label className="text-lg">Subject</label>
                  </div>
                  <div className="w-1/4">
                    <label className="text-lg">Tag</label>
                  </div>
                </div>
                <div className="w-2/5 flex flex-row ">
                  <label className="text-lg">Preview</label>
                </div>
              </div>
            </div>

            {/* Render the notes */}
            <div className="flex flex-col divide-y-2 overflow-auto">
            {/* Show the loading animation when the page is loading */}
            {this.state.isLoading && 
              <div className="w-full flex justify-center mt-10">
                <l-cardio size="80" stroke="6" speed="2" color="black" />
              </div> 
            }
            {/* Check to see if there are notes for a patient */}
            {this.state.originalNoteList.length === 0 && !this.state.isLoading ? 
              <div className="flex flex-col h-screen w-full pl-4 mt-10">
                <label className="text-6xl font-extrabold text-primary text-center"> 
                  No Notes For This Patient
                </label>
                <div className="text-2xl text-center text-gray-500 mt-6">
                  Add a note manually or write a note for a session or data set. Notes are automatically created when a patient's information is changed
                </div>
              </div>
            : 
              this.state.notes.map(block => <NoteSimple
                key={block.nid}
                note={block}/>)
            }
            </div>

          </section>
        </section>
        <AddNote
          visible={this.state.showAddNoteModal}
          handleOnExit={this.handleCloseModal}
          pid={this.state.pid}
          addNote={this.addNote}/>
        <AddMedication 
          visible={this.state.showAddMedicationModal}
          handleOnExit={this.handleCloseModal}
          updateMeds={this.updateMeds}/>
        <RemovePatient 
          visible={this.state.showRemovePatientModal}
          handleOnExit={this.handleCloseModal}
          removePatient={this.removePatient}
          pid={this.state.pid}/>
        <EditPatient 
          visible={this.state.showEditPatientModal}
          handleOnExit={this.handleCloseModal}
          editPatientData={this.editPatientData}
          patientData={formatData(this.state.patientData)}
          pid={this.state.pid}
          addNote={this.addNote}/>
      </section>
    )
  }
};

export default withRouter(PatientOverview);