import React, { Component } from 'react';


/**
 * Dropdown menu component
 */
export class Dropdown extends Component {
  /**
   * Dropdown constructor
   * @param {Object} props Props
   */
  constructor(props){
    super();
    /**
     * Dropdown component state
     * @type {Object}
     * @property {Object} selectedValue Value and label for the dropdown selection
     * @property {boolean} showMenu Show dropdown options
     */
    this.state = {
      selectedValue: {
        value: props.options[0].value,
        label: props.options[0].label
      },
      showMenu: false,
      useDisplayValue: true
    }
    this.handleInputClick = this.handleInputClick.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  /**
   * Handles opening and closing the dropdown menu
   * @param {event} event Click event
   */
  handleInputClick(event) {
    event.stopPropagation();
    this.setState({...this.state, showMenu: !this.state.showMenu});
  }

  /**
   * Set active option in state and passed function
   * @param {Object} option Set dropdown value
   */
  onItemClick(option) {
    this.setState({
      ...this.state, 
      selectedValue: option,
      showMenu: false,
      useDisplayValue: false
    });
    this.props.setValue(option.value);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  // Closes the dropdown menu if you click outside of it
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({...this.state,showMenu: false});
    }
  }

  getDisplayValue() {
    if(this.props.displayValue && this.state.useDisplayValue) {
      return(this.props.displayValue);
    } else {
      return(this.state.selectedValue.label);
    }
  }

  render() {
    return(
      <div ref={this.wrapperRef} className="relative w-full">
        <div className="justify-items-center w-full">
          <button
            className={`flex justify-between items-center border rounded-lg w-full py-2 px-4 shadow-md focus:outline-none ${
              this.props.disabled
                ? 'bg-gray-200 cursor-not-allowed'
                : 'bg-white hover:ring-2 hover:ring-blue-500'
            }`}
            onClick={this.handleInputClick}
            disabled={this.props.disabled}
          >
            {this.getDisplayValue()}
            {/* Dropdown Icon */}
            <svg className="ml-2 w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 011.06 1.06l-4.24 4.24a.75.75 0 01-1.06 0L5.23 8.29a.75.75 0 01.02-1.06z" clipRule="evenodd" />
            </svg>
          </button>
          {this.state.showMenu && (
            <div className="absolute z-40 bg-white min-w-full max-h-48 mt-1 overflow-auto border border-gray-200 rounded-lg shadow-lg py-2 transition-all ease-in-out duration-200">
              {this.props.options.map((option) => (
                <button className="flex justify-between items-center w-full px-4 py-2 text-left text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition ease-in-out duration-150"
                  onClick={() => this.onItemClick(option)}
                  key={option.value}>
                  <label className="cursor-pointer">{option.label}</label>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}

