import React, { Component } from 'react';
import { Dropdown } from './Dropdown';

/**
 * Component for selecting the month to display in patient data
 */
class DateSelection extends Component {
  render() {
    const { dates, setDate, loading } = this.props;

    if (loading) {
      return (
        <div className="w-full flex justify-center mt-2">
          <l-cardio size="50" stroke="4" speed="2" color="black" />
        </div>
      );
    }

    return (
      <>
        {dates.length > 0 ? (
          <Dropdown options={dates} setValue={setDate} disabled={false} />
        ) : (
          <div className="w-full flex justify-center mt-2 text-gray-500">
            No available dates
          </div>
        )}
      </>
    );
  }
}

export default DateSelection;
