import React, { Component } from "react";

export class SessionSimple extends Component {
  constructor(props){
    super(props);
    this.formatTitle = this.formatTitle.bind(this);
  }

  formatTitle() {
    if (this.props[0] === "gait_velocity") {
      return("Gait Velocity");
    } else if (this.props[0] === "form") {
      return("Form");
    }
  }

  render() {
    const { activeGraph, setGraph } = this.props;
    const metricData = this.props[1];
    const isActive = this.formatTitle() === activeGraph;
  
    return (
      <div
        className={`w-full rounded-lg mt-2 px-4 cursor-pointer transition-all ${
          isActive
            ? "border-2 border-blue-500 bg-blue-100"
            : "hover:border-blue-400 hover:bg-gray-100"
        }`}
        onClick={() => setGraph(this.formatTitle())}
      >
        {/* Title */}
        <div className="w-full text-center">
          <label className="font-bold text-lg text-gray-800">
            {this.formatTitle()}
          </label>
        </div>
  
        {/* Metric Data as Bulleted List */}
        <ul className="list-disc list-inside space-y-2 text-gray-700 text-sm">
          <li>
            <span className="font-medium">Average:</span>{" "}
            {parseFloat(metricData.avg).toFixed(2)}
          </li>
          <li>
            <span className="font-medium">Min:</span>{" "}
            {parseFloat(metricData.min).toFixed(2)}
          </li>
          <li>
            <span className="font-medium">Max:</span>{" "}
            {parseFloat(metricData.max).toFixed(2)}
          </li>
        </ul>
      </div>
    );
  }  
}