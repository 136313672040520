import React, { Component } from 'react';

export class PopOverMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {top: 0, left: 0}
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (this.props.showMenu && !prevProps.showMenu) {
      this.setMenuPosition();
    }
  }
  
  setMenuPosition() {
    const { buttonRef } = this.props;
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const menuWidth = 150; // assuming a width for your menu

    let left = buttonRect.right + 10; // default position to the right of the button
    if (window.innerWidth - buttonRect.right < menuWidth) {
      left = buttonRect.left - menuWidth - 10; // if too close to the right edge, move left
    }

    const top = buttonRect.top;

    this.setState({ position: { top, left } });
  }

  // Closes the menu if you click outside of it
  handleClickOutside(event) {
    if(this.props.showMenu) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
          this.props.handleOnClose()
      }
    }
  }

  render() {
    if (!this.props.showMenu) return null;

    const { position } = this.state;

    return (
      <div
        ref={this.wrapperRef}
        style={{
          position: 'absolute',
          top: position.top,
          left: position.left,
          zIndex: 1000
        }}
      >
        <div className="absolute w-auto min-w-40 bg-white border border-gray-300 shadow-lg rounded-lg">
          <ul className="py-2">
            {this.props.menuItems.map((item, index) => (
              <li
                key={index}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer whitespace-nowrap text-center"
                onClick={item.onClick}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
  
  