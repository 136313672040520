export default function formatData(patientData) {
    let returnMap = {}
    
    // Grab the month, day, and year values
    if(patientData.dob) {
        const dateValues = patientData.dob.split('/');
        returnMap["month"] = getMonthString(dateValues[0]);
        returnMap["day"] = dateValues[1];
        returnMap["year"] = dateValues[2];
        returnMap["dob"] = patientData.dob;
    } else {
        returnMap["month"] = "";
        returnMap["day"] = "";
        returnMap["year"] = "";
    }

    // Convert height to feet and inches
    if(patientData.height) {
        returnMap["feet"] = (Math.floor(patientData.height / 12) + " Feet");
        if(patientData.height % 12 === 1) {
            returnMap["inches"] = (patientData.height % 12 + " Inch");
        } else {
            returnMap["inches"] = (patientData.height % 12 + " Inches");
        }
        returnMap["height"] = patientData.height;
    } else {
        returnMap["feet"] = "";
        returnMap["inches"] = "";
    }

    if(patientData.firstName) {
        returnMap["firstName"] = patientData.firstName;
    } else {
        returnMap["firstName"] = "";
    }

    if(patientData.lastName) {
        returnMap["lastName"] = patientData.lastName;
    } else {
        returnMap["lastName"] = "";
    }

    if(patientData.weight) {
        returnMap["weight"] = patientData.weight;
    } else {
        returnMap["weight"] = "";
    }

    if(patientData.sex) {
        returnMap["sex"] = getSex(patientData.sex);
    } else {
        returnMap["sex"] = "";
    }

    if(patientData.pid) {
        returnMap["pid"] = patientData.pid;
    }
    if(patientData.patientID) {
        returnMap["patientID"] = patientData.patientID;
    }
    return returnMap;
}

function getMonthString(month) {
    if(month === "01" || month === "1"){ return "Jan"}
    if(month === "02" || month === "2"){ return "Feb"}
    if(month === "03" || month === "3"){ return "Mar"}
    if(month === "04" || month === "4"){ return "Apr"}
    if(month === "05" || month === "5"){ return "May"}
    if(month === "06" || month === "6"){ return "Jun"}
    if(month === "07" || month === "7"){ return "Jul"}
    if(month === "08" || month === "8"){ return "Aug"}
    if(month === "09" || month === "9"){ return "Sept"}
    if(month === "10"){ return "Oct"}
    if(month === "11"){ return "Nov"}
    if(month === "12"){ return "Dec"}
}

function getSex(sex) {
    if(sex === "Male" || sex === "M" || sex === "m" || sex === "male") {return "Male"}
    if(sex === "Female" || sex === "F" || sex === "f" || sex === "female") {return "Female"}
}

export function getPatientDataChanges(originalData, newData) {
    let changes = {};

    // Check for changes in the patient data
    if(originalData.dob != newData.dob) {
        changes["dob"] = {
            oldValue: originalData.dob,
            newValue: newData.dob
        }
    }

    if(originalData.firstName != newData.firstName) {
        changes["firstName"] = {
            oldValue: originalData.firstName,
            newValue: newData.firstName
        }
    }

    if(originalData.lastName != newData.lastName) {
        changes["lastName"] = {
            oldValue: originalData.lastName,
            newValue: newData.lastName
        }
    }

    if(originalData.height != newData.height) {
        changes["height"] = {
            oldValue: originalData.height,
            newValue: newData.height
        }
    }

    if(originalData.weight != newData.weight) {
        changes["weight"] = {
            oldValue: originalData.weight,
            newValue: newData.weight
        }
    }

    if(originalData.sex != newData.sex) {
        changes["sex"] = {
            oldValue: originalData.sex,
            newValue: newData.sex
        }
    }

    return(changes);
}

export function validatePatientData(data) {
    let errors = [];

    // Validate that first and last names do not contain spoaces
    if (!/^[a-zA-Z]+$/.test(data.fistName)) {
        errors.push({
            message: "First name is formatted incorrectly"
        });
    }
    if(data.firstName.length === 0) {
        errors.push({
            message: "First name cannot be empty"
        });
    }
    if (!/^[a-zA-Z]+$/.test(data.lastName)) {
        errors.push({
            message: "Last name is formatted incorrectly"
        });
    }
    if(data.lastName.length === 0) {
        errors.push({
            message: "Last name cannot be empty"
        });
    }
    if(!Number.isInteger(Number(data.weight))) {
        errors.push({
            message: "Weight should be a number"
        });
    }
    if(data.weight.length === 0) {
        errors.push({
            message: "Weight name cannot be empty"
        });
    }
    if(data.weight > 500 || data.weight < 60) {
        errors.push({
            message: "Weight is invalid"
        });
    }

    return(errors);
}

export function getNoteContent(changes) {
    const changeDescriptions = [];

    for (const [key, change] of Object.entries(changes)) {
        const { oldValue, newValue } = change;
        changeDescriptions.push(
            `${key.charAt(0).toUpperCase() + key.slice(1)} was updated from "${oldValue}" to "${newValue}".`
        );
    }

    return changeDescriptions.join(" ");
}