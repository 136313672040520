import React, { Component, createRef } from 'react';

class PopdownFilterMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedOptions: {}
    };

    // Create a ref for the menu container
    this.menuRef = createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  // Function which closes the menu when clicking outside of it
  handleOutsideClick = (event) => {
    if (this.menuRef.current && !this.menuRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  toggleMenu = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  // Function handling adding / removing filters
  handleOptionChange = (filterType, value) => {
    this.setState(prevState => {
      const currentSelections = prevState.selectedOptions[filterType] || [];
      let updatedSelections;

      if (currentSelections.includes(value)) {
        updatedSelections = currentSelections.filter(item => item !== value);
      } else {
        updatedSelections = [...currentSelections, value];
      }

      return {
        selectedOptions: {
          ...prevState.selectedOptions,
          [filterType]: updatedSelections
        }
      };
    }, () => {
      this.updateFilters();
    });
  };

  updateFilters = () => {
    this.props.filterNotes(this.state.selectedOptions);
  };

  renderFilterSection = (filterType, options) => {
    const { selectedOptions } = this.state;
    const currentSelections = selectedOptions[filterType] || [];

    return (
      <div key={filterType} className="mb-4">
        <h3 className="font-bold mb-2">{filterType}</h3>
        <hr className="border-gray-300 mb-2" />
        {options.map((option, index) => (
          <div key={index} className="mb-2">
            <input
              type="checkbox"
              id={`${filterType}-${index}`}
              name={filterType}
              value={option.value}
              checked={currentSelections.includes(option.value)}
              onChange={() => this.handleOptionChange(filterType, option.value)}
              className="mr-2"
            />
            <label htmlFor={`${filterType}-${index}`} className="text-gray-700">
              {option.label}
            </label>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { isOpen } = this.state;
    const { filters } = this.props;

    return (
      <div className="relative w-full h-full">
        <button
          className="w-full h-full rounded-md bg-primary hover:bg-accent text-white"
          onClick={this.toggleMenu}
        >
          Filter Notes
        </button>

        {isOpen && (
          <div
            className="absolute mt-2 max-h-64 overflow-auto w-full bg-white border border-gray-300 rounded-md shadow-lg p-4"
            ref={this.menuRef}
          >
            {Object.keys(filters).map(filterType =>
              this.renderFilterSection(filterType, filters[filterType])
            )}
          </div>
        )}
      </div>
    );
  }
}

export default PopdownFilterMenu;
