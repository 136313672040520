/**
 * 
 * @param {array} notes
 * @returns {map} the filters that will get rendered in the filter menu
 */

export function getFilters(notes) {
    const filters = {
        Author: [],
        Tag: []
    };
    
    const authorSet = new Set();
    const tagSet = new Set();
    
    notes.forEach(note => {
    // Add unique authors
        if (!authorSet.has(note.author)) {
            authorSet.add(note.author);
            filters.Author.push({ value: note.author, label: note.author });
        }

        // Add unique tags
        if (!tagSet.has(note.tag)) {
            tagSet.add(note.tag);
            filters.Tag.push({ value: note.tag, label: note.tag });
        }
    });
    return filters;
}

export function getFilteredNotes(notes, filters) {
    // Extract filter values for authors and tags
    const filterAuthors = filters.Author || [];
    const filterTags = filters.Tag || [];


    // Check if there are any filters selected
    if (filterAuthors.length === 0 && filterTags.length === 0) {
        return notes; // Return original notes if no filters are selected
    }
    
    return notes.filter(note => {
        // Check if the note's author matches any of the filtered authors
        const authorMatches = filterAuthors.length > 0 ? filterAuthors.includes(note.author) : true;
        // Check if the note's tag matches any of the filtered tags
        const tagMatches = filterTags.length > 0 ? filterTags.includes(note.tag) : true;
    
        // Return true if both conditions are met when filters are applied
        return authorMatches && tagMatches;
    });
}