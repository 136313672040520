import React, { Component } from 'react';
import { cardio } from 'ldrs';

import { createPostRequest, createPutRequest } from '../../auth/utils/axios-utils';
import { Dropdown } from '../../core/components/Dropdown';

import { MONTH_LABELS, DATE_LABELS, YEAR_LABELS, FEET_LABELS, INCHES_LABELS } from  '../../../resources/Config';
import { getPatientDataChanges, validatePatientData, getNoteContent } from '../utils/format-patient-data';

class EditPatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: this.props.patientData.firstName,
      lastName: this.props.patientData.lastName,
      dob: this.props.patientData.dob,
      height: this.props.patientData.height,
      month: this.props.patientData.month,
      day: this.props.patientData.day,
      year: this.props.patientData.year,
      feet: this.props.patientData.feet,
      inches: this.props.patientData.inches,
      weight:this.props.patientData.weight,
      sex:this.props.patientData.sex,
      pid: this.props.pid,
      errors: []
    };
    this.submitModal = this.submitModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setSex = this.setSex.bind(this);
    this.setMonth = this.setMonth.bind(this);
    this.setDate = this.setDate.bind(this);
    this.setYear = this.setYear.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.setFeet = this.setFeet.bind(this);
    this.setInches = this.setInches.bind(this);
    this.calcHeight = this.calcHeight.bind(this);
    cardio.register();
  }

  // Lifecycle method that avoids state issues when opening the modal
  componentDidUpdate(prevProps) {
    // Check if the modal was opened and patientData has changed
    if (this.props.visible && !prevProps.visible) {
        this.setState({
            firstName: this.props.patientData.firstName,
            lastName: this.props.patientData.lastName,
            dob: this.props.patientData.dob,
            height: this.props.patientData.height,
            month: this.props.patientData.month,
            day: this.props.patientData.day,
            year: this.props.patientData.year,
            feet: this.props.patientData.feet,
            inches: this.props.patientData.inches,
            weight: this.props.patientData.weight,
            sex: this.props.patientData.sex,
            pid: this.state.pid,
        });
    }
}

  async submitModal() {
    let changes = getPatientDataChanges(this.props.patientData, this.state);
    let errors = validatePatientData(this.state);
    if(Object.keys(changes).length == 0){
      this.setState({
        errors: [{message: "No changes were made"}]
      });
      return;
    }
    if(!errors.length == 0) {
      this.setState({
        errors: errors
      });
      return;
    }
    const updatedPatient = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      providerId: this.state.providerId,
      dob: this.state.dob,
      height: this.state.height,
      weight: this.state.weight,
      sex: this.state.sex
    };
    // Configure the Edit Patient request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const editPatientBody = { title: "Update Patient Request", info: updatedPatient };
    const editPatientPath = "/updatePatient/" + this.props.pid;

    // Configure the Add Note Request
    const note = {
      subject: "Patient Data Updated",
      content: getNoteContent(changes),
      tag: "auto"
    };
    const addNotePath = "/addNote/" + this.props.pid;
    const addNoteBody = { info: note }

    // Call the backend to update the patient information
    try {
      const updatePatientResponse = await createPutRequest(editPatientPath, headers, editPatientBody);
      const addNoteResponse = await createPostRequest(addNotePath, headers, addNoteBody);
      this.props.editPatientData(updatePatientResponse, Object.values(addNoteResponse)[0]);
    } catch(error) {
      console.log(error);
    }
  }

  closeModal() {
    // Reset the state
    this.setState({...this.state, 
      firstName: this.props.patientData.firstName,
      lastName: this.props.patientData.lastName,
      dob: this.props.patientData.dob,
      height: this.props.patientData.height,
      month: this.props.patientData.month,
      day: this.props.patientData.day,
      year: this.props.patientData.year,
      feet: this.props.patientData.feet,
      inches: this.props.patientData.inches,
      weight:this.props.patientData.weight,
      sex:this.props.patientData.sex,
      pid: this.props.pid,
      errors: []
    });

    this.props.handleOnExit();
  }

  setSex (event) {
    this.setState({...this.state,
      sex: event.target.value,
    });
  }
  setMonth (month) {
    this.setState({...this.state,
      month: month,
      dob: this.formatDate(0, month)
    });
  }

  setYear (year) {
    this.setState({...this.state,
      year: year,
      dob: this.formatDate(2, year)
    });
  }

  setDate (date) {
    this.setState({...this.state,
      day: date,
      dob: this.formatDate(1, date)
    });
  }
  /**
   * Format date given input
   * @param {int} param 0=MM, 1=DD, 2=YYYY
   * @param {string} num Given number to format
   * @returns Formatted date string
   */
  formatDate(param, num) {
    let date;
    switch (param) {
      case 0:
        date = num + "/" + this.state.day + "/" + this.state.year;
        break;
      case 1:
        date = this.state.month + "/" + num + "/" + this.state.year;
        break;
      case 2:
        date = this.state.month + "/" + this.state.day + "/" + num;
    }
    console.log(date);
    return date;
  }

  setFeet (feet) {
    this.setState({...this.state,
      feet: feet,
      height: this.calcHeight(false, feet)
    });
  }
  setInches (inches) {
    this.setState({...this.state,
      inches: inches,
      height: this.calcHeight(true, inches)
    });
  }
  /**
   * Calculate Height from given params
   * @param {bool} inch Is inches? If not, assume feet
   * @param {string} val Measurement value 
   * @returns Height in inches
   */
  calcHeight(inch, val) {
    let height;
    if (inch) {
      height = parseInt(this.state.feet) * 12 + parseInt(val);
    } else {
      height = parseInt(val) * 12 + parseInt(this.state.inches);
    }
    return height;
  }

  render() {
    if(!this.props.visible) return null;

    return (
      <div className="fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
        <div className="bg-white w-1/4 overflow-auto overscroll-none pb-6 rounded">
          <div className="sticky top-0 z-40 flex items-center border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2] p-2 relative">
            <label className="text-2xl font-bold pb-2 flex-grow text-center">
              Edit Patient
            </label>
            <button 
              className="absolute right-4 px-3 py-1 text-xl font-semibold rounded-lg hover:bg-red-400"
              onClick={() => this.closeModal()}>
              X
            </button>
          </div>

          {/* Error Messages */}
          {this.state.errors.length > 0 && (
            <div className="mt-4 px-4">
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                <ul className="list-disc ml-5">
                  {this.state.errors.map((error, index) => (
                    <li key={index}>{error.message}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {/* Input Fields */}
          <div className="relative mt-3 px-4">
            <label className="font-medium text-gray-900">
              First Name
            </label> 
            <input
              onChange={event => this.setState({...this.state,firstName:event.target.value})}
              onKeyPress={this.handleKeypress}
              className="block w-full px-4 py-4 mt-! text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
              value={this.state.firstName}
              placeholder={this.props.patientData.firstName}
            />
          </div>

          <div className="relative mt-3 px-4">
            <label className="font-medium text-gray-900">
              Last Name
            </label> 
            <input
              onChange={event => this.setState({...this.state,lastName:event.target.value})}
              onKeyPress={this.handleKeypress}
              className="block w-full px-4 py-4 mt-! text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
              value={this.state.lastName}
              placeholder={this.props.patientData.lastName}
            />
          </div>

          <div className="relative mt-3 px-4">
            <label className="font-medium text-gray-900">
              Date of Birth
            </label>
            <div className="flex">
              <div className="relative w-1/3 mr-1">
                <Dropdown 
                  options={MONTH_LABELS}
                  setValue={this.setMonth}
                  displayValue={this.props.patientData.month}/>
              </div>
              <div className="relative w-1/3 mr-1">
                <Dropdown 
                  options={DATE_LABELS} 
                  setValue={this.setDate}
                  displayValue={this.props.patientData.day}/>
              </div>
              <div className="relative w-1/3 mr-1">
                <Dropdown 
                  options={YEAR_LABELS}
                  setValue={this.setYear}
                  displayValue={this.props.patientData.year}/>
              </div>
            </div>
          </div>

          <div className="relative mt-3 px-4">
            <label className="font-medium text-gray-900">
              Height
            </label> 
            <div className="flex">
              <div className="relative w-1/2 mr-1">
                <Dropdown 
                  options={FEET_LABELS} 
                  setValue={this.setFeet}
                  displayValue={this.props.patientData.feet}/>
              </div>
              <div className="relative w-1/2 mr-1">
                <Dropdown 
                  options={INCHES_LABELS} 
                  setValue={this.setInches}
                  displayValue={this.props.patientData.inches}/>
              </div>
            </div>
          </div>

          <div className="relative mt-3 px-4">
            <label className="font-medium text-gray-900">
              Weight
            </label> 
            <input
              onChange={event => this.setState({...this.state,weight:event.target.value})}
              onKeyPress={this.handleKeypress}
              className="block w-full px-4 py-4 mt-! text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
              value={this.state.weight}
              placeholder={this.props.patientData.weight}
            />
          </div>

          <div className="relative mt-3 px-4">
            <label className="font-medium text-gray-900">
              Sex
            </label> 
            <div onChange={this.setSex}>
              <input
                className="font-medium text-gray-900 hover:shadow-inner"
                type="radio"
                value="Male"
                name="gender"
                defaultChecked={this.props.patientData.sex === "Male"}/> Male
              <input
                className="font-medium text-gray-900 ml-3 hover:shadow-inner"
                type="radio"
                value="Female"
                name="gender"
                defaultChecked={this.props.patientData.sex === "Female"}/> Female
            </div>
          </div>

          <div className="relative mt-3 px-4">
            <button 
              className="mt-1 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
              onClick={() => this.submitModal()}>
                Submit
            </button>
          </div>
        </div>
      </div>
    )
  }
}
export default EditPatient;