import React, { Component } from "react";

export class RuleSimple extends Component {
  render() {
    const { compliant, rule, showGraph } = this.props[1];
    const { setGraph } = this.props;

    return (
      <li
        className={`cursor-pointer text-lg transition-all ${
          compliant ? "text-gray-700" : "text-red-600"
        } hover:font-semibold`}
        onClick={() => setGraph(showGraph)}
      >
        {rule}
      </li>
    );
  }
}
