/*
 * 
 * THIS FILE WILL CONTAIN CONSTANT VALUES THAT ARE USED ACROSS DIFFERENT FILES IN OUR PROJECT
 * WHEN DEVELOPING, IF YOU ARE USING ANY SORT OF CONSTANT VALUES, ADD THEM ACCORDINGLY IN
 * THIS FILE TO MAKE CODE MORE READABLE
 *
 */


/*
 *
 * API PATH AND TITLE VARIABLES
 *
 */
export const ACCESS_TOKEN_KEY = "x-access-token"
export const REFRESH_TOKEN_API_KEY = "x-refresh-token"

export const LOGIN_PATH = "/login"
export const LOGIN_TITLE = "Login Request"

export const SIGNUP_PATH = "/signup"
export const SIGNUP_TITLE = "Account Signup"

export const IS_AUTH_PATH = "/isAuth"

export const GET_ACCOUNT_PATH = "/getAccount"
export const GET_ACCOUNT_TITLE = "Get Account"

export const GET_PATIENT_PATH = "/getPatient/"
export const GET_PATIENT_TITLE = "Get Patient"

export const GET_PATIENTS_PATH = "/getPatients"

export const GET_PATIENT_DATA_PATH = "/getPatientData"
export const GET_PATIENT_DATA_TITLE = "Patient Data Request"

export const GET_AGGREGATE_DATA_PATH = "/getAggDoc"
export const GET_RESP_AGGREGATE_DATA_PATH = "/getRespAggDoc"
export const GET_AGGREGATE_DATA_TITLE = "Aggregation Document Request"

export const ADD_PATIENT_PATH = "/addPatient"
export const ADD_PATIENT_TITLE = "Add Patient Request"

export const ADD_MEDICATION_PATH = "/addMedication"
export const ADD_MEDICATION_TITLE = "Add Medication"

export const REMOVE_PATIENT_PATH = "/removePatient"
export const REMOVE_PATIENT_TITLE = "Remove Patient Request"
export const REMOVE_PATH = "/portal/rm/"

export const REMOVE_MEDICATION_PATH = "/removeMedication"
export const REMOVE_MEDICATION_TITLE = "Remove Medication Request"

export const UPDATE_USER_PATH = "/updateUser"
export const UPDATE_USER_TITLE = "User Update Request"

export const ADD_ORG_USER_PATH = "/addOrgUser"
export const ADD_ORG_USER_TITLE = "Add User To Org"
export const GET_ORG_USER_PATH = "/getOrgUsers"

export const UPDATE_ORG_NAME_PATH = "/updateOrgName"
export const UPDATE_ORG_NAME_TITLE = "Update Org Name Request"

export const UPDATE_USER_PASSWORD_PATH = "/updateUserPassword"
export const UPDATE_USER_PASSWORD_TITLE = "Update User Password Request"

export const UPDATE_USER_PERMISSION_PATH = "/updateUserPermissions"

export const REMOVE_USER_PATH = "/rmUser"
export const REMOVE_USER_TITLE = "Remove User Request"

export const ADD_MONITORING_SESSION_PATH = "/addMonitoringSession"
export const ADD_MONITORING_SESSION_TITLE = "Add Monitoring Session Request"

export const GET_TEMPLATES_PATH = "/getSessionTemplates"

export const GET_ACTIVE_SESSIONS_PATH = "/getActiveSessions"

export const VERIFY_2FA_PATH = "/verify_2fa"

export const FORGOT_PASS_PATH = "/forgotPassword"

export const RESET_PASS_PATH = "/resetPassword"

/*
 *
 * LOCAL / SESSION STORAGE AND PATH VARIABLES VARIABLES
 * 
 */
export const INFO_KEY = "info"
export const DATA_KEY = "data"
export const AUTH_TOKEN_KEY = "auth_token"
export const REFRESH_TOKEN_KEY = "refresh_token"

export const PORTAL_PAGE_PATH = "/portal"
export const LOGIN_PAGE_PATH = "/login"
export const SIGNUP_PAGE_PATH = "/signup"
export const LOGOUT_PATH = "/logout"
export const PATIENT_DATA_PAGE_PATH = "/portal/patient-data/"
export const DASHBOARD_PAGE_PATH = "/dashboard"
export const FORGOT_PASS_PAGE_PATH = "/forgotPassword"
export const RESET_PASS_PAGE_PATH = "/resetPassword"



/*
 *
 * DOCUMENT TITLE VARIABLES
 *
 */
export const DASHBOARD_PAGE_TITLE = "Dashboard | Patient Management Portal"
export const HOME_PAGE_TITLE = "Patient Management Portal"
export const LOGIN_PAGE_TITLE = "Login | Patient Management Portal"
export const PATIENT_DATA_PAGE_TITLE = "Patient Data | Patient Management Portal"
export const PAYMENT_PAGE_TITLE = "Payment | Patient Management Portal"
export const PORTAL_PAGE_TITLE = "Portal | Patient Management Portal"
export const SIGNUP_PAGE_TITLE = "Signup | Patient Management Portal"
export const FORGOT_PASS_PAGE_TITLE = "Forgot Password | Patient Management Portal"
export const RESET_PASS_PAGE_TITLE = "Reset Password | Patient Management Portal"

/*
 *
 * CMS COMPONENT VARIABLES
 * 
 */
export const COMPLIANCE_TRIAGE_COMPONENT = "complianceTriage" 
export const COMPLIANCE_TRIAGE_TITLE = "Compliance Triage"

export const RESPIRATORY_COMPONENT = "respiratory"
export const RESPIRATORY_TITLE = "Respiratory Reporting"

export const MSK_COMPONENT = "msk"
export const MSK_TITLE = "MSK Reporting"

export const ACTIVITY_MEDICATION_COMPONENT = "activityMedication"
export const ACTIVITY_MEDICATION_TITLE = "Activity & Medication"

/*
 *
 * PERMISSION LEVEL CONSTANTS
 *     (NOTE THAT THESE VALUES MAY CHANGE IN THE FUTURE)
 * 
 */
export const PERMISSIONS = {
  OWNER: "owner",
  ADMIN: "admin",
  USER: "user"
}

/*
 *
 * TAB AND GRAPH COMPONENT VARIABLES
 * 
 */
export const MUSCULOSKELETAL_TAB_NAME = "Musculoskeletal Data"
export const MUSCULOSKELETAL_TAB_ID = "0"
export const RESPIRATORY_TAB_NAME = "Respiratory Data"
export const RESPIRATORY_TAB_ID = "1"

export const DATE_LABEL = "Date"
export const DATE_DATA_KEY = "date"

export const SHAKE_COUNT_X_LABEL = "Inhaler Shake Count"
export const SHAKE_COUNT_Y_LABEL = "Number of Occurances"
export const SHAKE_COUNT_GRAPH_TITLE = "Inhaler Shake Count Distribution"

export const TRIP_COUNT_Y_LABEL = "Trip Count"
export const TRIP_COUNT_GRAPH_TITLE = "Trip Count By Date"
export const TRIP_COUNT_GRAPH_DATA_KEY_Y = "trip_count"

export const CONFIDENCE_Y_LABEL = "Confidence"
export const CONFIDENCE_GRAPH_NAME = "Confidence Over Time"
export const CONFIDENCE_GRAPH_DATA_KEY_Y = "confidence"

export const FORM_Y_LABEL = "Form"
export const FORM_GRAPH_NAME = "Form Over Time"
export const FORM_GRAPH_DATA_KEY_Y = "form"

export const ACC_DISTRIBUTION_GRAPH = "accDistribution"
export const FORM_GRAPH = "formOverTime"
export const CLASSIFICATION_GRAOH = "classificationConfidence"
export const TRIP_COUNT_GRAPH = "tripCount"
export const INHALER_SHAKE_GRAPH = "inhalerShake"